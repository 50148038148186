import { useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { Template } from "types";
import { getAllTemplateList, getTemplateList } from "modules/templates/api";
import { useTemplateListFilterAndSort } from "../useTemplateListFilterAndSort";

export const useTemplateList = (filter = true) => {
  const filterAndSortTemplates = useTemplateListFilterAndSort(filter);

  const [loadAll, setLoadAll] = useState(false);

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<{
    templateList: Template[];
    nextToken: string | null;
  }>({
    queryKey: loadAll ? [QueryKeys.Templates, "all"] : [QueryKeys.Templates],
    queryFn: ({ pageParam }) =>
      loadAll
        ? getAllTemplateList()
        : getTemplateList(pageParam as string | null),
    getNextPageParam: (lastPage) => lastPage.nextToken ?? undefined,
    initialPageParam: null,
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const templateList = useMemo(() => {
    const templates = data?.pages
      ? data.pages.flatMap((page) => page.templateList)
      : [];

    return filterAndSortTemplates(templates);
  }, [data, filterAndSortTemplates]);

  return {
    templateList,
    fetchNextPage: (all?: boolean) => {
      if (all) setLoadAll(true);
      fetchNextPage();
    },
    hasNextPage,
    isLoading,
  };
};
