import { SelectionSet } from "aws-amplify/api";
import { Schema } from "queryClient";
import { TypeKeysEnum } from "./General.types";
import utils from "utils";

export const TaskSelectionSet = [
  "id",
  "owner",
  "createdAt",
  "updatedAt",
  "taskType.*",
  "taskTypeID",
  "website.*",
  "websiteID",
  "effort",
  "date",
  "time",
  "deadlineDate",
  "deadlineTime",
  "billType",
  "billed",
  "status",
  "employee",
  "reportEntries.*",
] as const;

export type Task = SelectionSet<
  Schema["Task"]["type"],
  typeof TaskSelectionSet
>;

export type CreateTaskInput = Schema["Task"]["createType"];

export type UpdateTaskInput = Schema["Task"]["updateType"];

export type BillType = Schema["BillType"]["type"];

export type TaskStatus = Schema["TaskStatus"]["type"];

export const TaskTypeKeys: TypeKeysEnum<Task> = {
  id: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.id,
  },
  createdAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.createdAt) +
      " Uhr",
  },
  updatedAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.updatedAt) +
      " Uhr",
  },
  owner: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: (data) => data.owner ?? "",
  },
  taskType: {
    type: "entity",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  taskTypeID: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.taskTypeID,
  },
  website: {
    type: "entity",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  websiteID: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.websiteID,
  },
  effort: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.effort.toString(),
  },
  date: {
    type: "date",
    isArray: false,
    isRequired: false,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      data.date ? utils.dates.getDateInGermanFromAWSDateFormat(data.date) : "",
  },
  time: {
    type: "time",
    isArray: false,
    isRequired: false,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      data.time
        ? utils.dates.getTimeInGermanFromAWSTimeFormat(data.time) + " Uhr"
        : "",
  },
  deadlineDate: {
    type: "date",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateInGermanFromAWSDateFormat(data.deadlineDate),
  },
  deadlineTime: {
    type: "time",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getTimeInGermanFromAWSTimeFormat(data.deadlineTime) + " Uhr",
  },
  billType: {
    type: "enum",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.billType,
  },
  billed: {
    type: "boolean",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => (data.billed ? "Ja" : "Nein"),
  },
  status: {
    type: "enum",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.status,
  },
  employee: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.employee,
  },
  reportEntries: {
    type: "entity",
    isArray: true,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
};

export const isKeyOfTask = (key: string): key is keyof Task =>
  key in TaskTypeKeys;
