import { useNavigate } from "react-router-dom";
import { PermanentTask } from "types";
import { Routes } from "routes";

export const usePermanentTaskRoutes = () => {
  const navigate = useNavigate();

  const getCreatePermanentTaskPageRoute = () =>
    Routes.permanenttasks.subNavigations.permanenttaskcreate.path;

  const getEditPermanentTaskPageRoute = (permanentTask: PermanentTask) =>
    Routes.permanenttasks.subNavigations.permanenttask.subNavigations.permanenttaskedit.path.replace(
      ":permanentTaskID",
      permanentTask.id,
    );

  const getPermanentTaskPageRoute = (permanentTask: PermanentTask) =>
    Routes.permanenttasks.subNavigations.permanenttask.path.replace(
      ":permanentTaskID",
      permanentTask.id,
    );

  const getPermanentTaskListPageRoute = () => Routes.permanenttasks.path;

  const navigateToCreatePermanentTaskPage = () => {
    navigate(getCreatePermanentTaskPageRoute());
  };

  const navigateToEditPermanentTaskPage = (permanentTask: PermanentTask) => {
    navigate(getEditPermanentTaskPageRoute(permanentTask));
  };

  const navigateToPermanentTaskPage = (permanentTask: PermanentTask) => {
    navigate(getPermanentTaskPageRoute(permanentTask));
  };

  const navigateToPermanentTaskListPage = () => {
    navigate(getPermanentTaskListPageRoute());
  };

  return {
    getCreatePermanentTaskPageRoute,
    getEditPermanentTaskPageRoute,
    getPermanentTaskPageRoute,
    navigateToCreatePermanentTaskPage,
    navigateToEditPermanentTaskPage,
    navigateToPermanentTaskPage,
    navigateToPermanentTaskListPage,
  };
};
