import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useAuthContext } from "context";
import { QueryKeys } from "queryClient";
import { getBooking } from "modules/bookings/api";

type BookingReadParamsType = {
  bookingID: string;
};

export const useBookingRead = () => {
  const authContext = useAuthContext();

  const { bookingID } = useParams<BookingReadParamsType>();

  console.log("Loading booking with ID: ", bookingID);

  const { data: booking, isLoading } = useQuery({
    queryKey: [QueryKeys.Bookings, bookingID],
    queryFn: async () => {
      if (!bookingID) return null;

      const bookingFromAPI = await getBooking(bookingID);

      authContext.setBooking(bookingFromAPI);

      return bookingFromAPI;
    },
  });

  return {
    bookingID,
    booking: booking ?? null,
    isLoading,
  };
};
