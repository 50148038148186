import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  Customer,
  CustomerTypeKeys,
  SearchTextParamName,
  SortDirection,
  isKeyOfCustomer,
} from "types";
import {
  FilterField,
  filterEntities,
  filterEntityByField,
  getComparisonType,
  getSearchFields,
  getSortFields,
  sortEntities,
  sortEntitiesByField,
} from "utils/tables";

const searchFields = getSearchFields<Customer>(CustomerTypeKeys);

export const useCustomerListFilterAndSort = (filter = true) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<Customer>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof Customer, string>> = {};

    const filters: FilterField<Customer>[] = Array.from(
      searchParams.entries(),
    ).map(([field, value]) => ({
      field: field as keyof Customer,
      value,
      comparisonType: getComparisonType(searchParams, field),
    }));

    const defaultFilterFields: FilterField<Customer>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof Customer,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof Customer, SortDirection>> = {
      Account_Name: SortDirection.asc,
    };

    return getSortFields<Customer>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfCustomer,
    );
  }, [searchParams]);

  const filterAndSortCustomers = useCallback(
    (
      customerList: Customer[],
      customSortFunctions: Partial<
        Record<
          keyof Customer,
          (a: Customer, b: Customer, direction: SortDirection) => number
        >
      > = {},
      customFilterFunctions: Partial<
        Record<
          keyof Customer | (string & {}),
          (entity: Customer, value: string) => boolean
        >
      > = {},
    ) =>
      sortEntities<Customer>(
        filter
          ? filterEntities<Customer>(
              customerList,
              filterFields,
              searchFields,
              searchParams.get(SearchTextParamName.SearchCustomer),
              filterEntityByField<Customer>,
              CustomerTypeKeys,
              customFilterFunctions,
            )
          : customerList,
        sortFields,
        sortEntitiesByField<Customer>,
        CustomerTypeKeys,
        customSortFunctions,
      ),
    [filter, filterFields, searchParams, sortFields],
  );

  return filterAndSortCustomers;
};
