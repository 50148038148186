import { SelectionSet } from "aws-amplify/api";
import { Schema } from "queryClient";
import { TypeKeysEnum } from "./General.types";
import utils from "utils";

export const TemplateSelectionSet = [
  "id",
  "owner",
  "createdAt",
  "updatedAt",
  "name",
  "system.*",
  "systemID",
  "usability",
  "beauty",
  "purchasedLicences",
  "providerURL",
  "usage",
  "description",
  "websites.*",
] as const;

export type Template = SelectionSet<
  Schema["Template"]["type"],
  typeof TemplateSelectionSet
>;

export type CreateTemplateInput = Schema["Template"]["createType"];

export type UpdateTemplateInput = Schema["Template"]["updateType"];

export const TemplateTypeKeys: TypeKeysEnum<Template> = {
  id: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.id,
  },
  createdAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.createdAt) +
      " Uhr",
  },
  updatedAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.updatedAt) +
      " Uhr",
  },
  owner: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: (data) => data.owner ?? "",
  },
  name: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.name,
  },
  system: {
    type: "entity",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  systemID: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.systemID,
  },
  usability: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.usability.toString(),
  },
  beauty: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.beauty.toString(),
  },
  purchasedLicences: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.purchasedLicences.toString(),
  },
  usage: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.usage.toString(),
  },
  providerURL: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.providerURL,
  },
  description: {
    type: "string",
    isArray: false,
    isRequired: false,
    isSortable: false,
    isSearchable: true,
    formatter: (data) => data.description ?? "",
  },
  websites: {
    type: "entity",
    isArray: true,
    isRequired: false,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
};

export const isKeyOfTemplate = (key: string): key is keyof Template =>
  key in TemplateTypeKeys;
