import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  SearchTextParamName,
  SortDirection,
  Task,
  TaskTypeKeys,
  isKeyOfTask,
} from "types";
import {
  FilterField,
  filterEntities,
  filterEntityByField,
  getComparisonType,
  getSearchFields,
  getSortFields,
  sortEntities,
  sortEntitiesByField,
} from "utils/tables";

const searchFields = getSearchFields<Task>(TaskTypeKeys);

export const useTaskListFilterAndSort = (filter = true) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<Task>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof Task, string>> = {};

    const filters: FilterField<Task>[] = Array.from(searchParams.entries()).map(
      ([field, value]) => ({
        field: field as keyof Task,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }),
    );

    const defaultFilterFields: FilterField<Task>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof Task,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof Task, SortDirection>> = {
      date: SortDirection.asc,
    };

    return getSortFields<Task>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfTask,
    );
  }, [searchParams]);

  const filterAndSortTasks = useCallback(
    (
      taskList: Task[],
      customSortFunctions: Partial<
        Record<
          keyof Task,
          (a: Task, b: Task, direction: SortDirection) => number
        >
      > = {},
      customFilterFunctions: Partial<
        Record<keyof Task, (entity: Task, value: string) => boolean>
      > = {},
    ) =>
      sortEntities<Task>(
        filter
          ? filterEntities<Task>(
              taskList,
              filterFields,
              searchFields,
              searchParams.get(SearchTextParamName.SearchTask),
              filterEntityByField<Task>,
              TaskTypeKeys,
              customFilterFunctions,
            )
          : taskList,
        sortFields,
        sortEntitiesByField<Task>,
        TaskTypeKeys,
        customSortFunctions,
      ).map((task) => ({
        ...task,
        updatedAt: new Date(
          new Date(task.updatedAt).getTime() + 1000,
        ).toISOString(),
      })),
    [filter, filterFields, searchParams, sortFields],
  );

  return filterAndSortTasks;
};
