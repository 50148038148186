import React, { SyntheticEvent } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useCustomerAllList, useWebsiteRoutes } from "hooks";
import { LastRunWebsiteTask } from "types";
import { CustomReactTable } from "core";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";
import { getBacklinkStatusName } from "modules/shared/options/BacklinkStatusOption";
import { LastRunColumnFormatter } from "../ColumnFormatters/LastRunColumnFormatter";
import utils from "utils";

type WebsiteListTableProps = {
  lastRunWebsiteTaskList: LastRunWebsiteTask[];
  isLoading: boolean;
  hasNextPage: boolean;
  fetchNextPage: (loadAll?: boolean) => void;
};

export const WebsiteListTable: React.FC<WebsiteListTableProps> = ({
  lastRunWebsiteTaskList,
  isLoading,
  hasNextPage,
  fetchNextPage,
}) => {
  const { navigateToWebsitePage, openWebsiteInBrowserHandler } =
    useWebsiteRoutes();

  const { customerList } = useCustomerAllList(false);

  const defaultColumns = React.useMemo<ColumnDef<LastRunWebsiteTask>[]>(
    () => [
      {
        header: "URL",
        accessorKey: "url",
        accessorFn: (row) => row.website.url,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Letzte Ausführung",
        accessorKey: "task.date",
        accessorFn: (row) =>
          row.task?.date
            ? utils.dates.getDateInGermanFromAWSDateFormat(row.task?.date)
            : "",
        cell: (info) => info.getValue(),
        enableSorting: false,
        footer: (props) => props.column.id,
      },
      {
        header: "Tage",
        accessorKey: "lastRun",
        cell: ({ row }) => <LastRunColumnFormatter row={row.original} />,
        footer: (props) => props.column.id,
      },
      {
        header: "Besitzer",
        accessorKey: "websiteOwner",
        accessorFn: (row) =>
          customerList.find(
            (customerItem) => customerItem.id === row.website.websiteOwner,
          )?.Account_Name,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Zahler",
        accessorKey: "websitePayer",
        accessorFn: (row) =>
          customerList.find(
            (customerItem) => customerItem.id === row.website.websitePayer,
          )?.Account_Name,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Website-Typ",
        accessorKey: "websiteType",
        accessorFn: (row) =>
          row.website.websiteType === "website"
            ? "Webseite"
            : row.website.websiteType === "webshop"
              ? "Webshop"
              : row.website.websiteType === "application"
                ? "Anwendung"
                : "Server",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "System",
        accessorKey: "systemID",
        accessorFn: (row) => row.website.system.name,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Hoster",
        accessorKey: "hosterID",
        accessorFn: (row) => row.website.hoster.name,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Template",
        accessorKey: "templateID",
        accessorFn: (row) => row.website.template.name,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Zuständig",
        accessorKey: "currentlyResponsible",
        accessorFn: (row) => (row.website.currentlyResponsible ? "Ja" : "Nein"),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Von uns erstellt",
        accessorKey: "createdByUs",
        accessorFn: (row) => (row.website.createdByUs ? "Ja" : "Nein"),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "GW Backlink Erstellung",
        accessorKey: "backlinkErstellung",
        accessorFn: (row) =>
          row.website.backlinkErstellung
            ? getBacklinkStatusName(row.website.backlinkErstellung)
            : "",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "GW Backlink Pflege",
        accessorKey: "backlinkPflege",
        accessorFn: (row) =>
          row.website.backlinkPflege
            ? getBacklinkStatusName(row.website.backlinkPflege)
            : "",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Formilo Backlink",
        accessorKey: "formiloBacklink",
        accessorFn: (row) =>
          row.website.formiloBacklink
            ? getBacklinkStatusName(row.website.formiloBacklink)
            : "",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "PHP-Version",
        accessorKey: "phpVersion",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Aufwandsdefinitionen",
        accessorKey: "effortDefinitions",
        accessorFn: (row) => row.website.effortDefinitions.length,
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Aktionen",
        cell: ({ row }) => (
          <ActionsColumnFormatter<LastRunWebsiteTask>
            row={row}
            openInBrowserHandler={(lastRunWebsiteTask) =>
              openWebsiteInBrowserHandler(lastRunWebsiteTask.website)
            }
            minWidth={150}
            listIntent="list"
          />
        ),
        footer: (props) => props.column.id,
      },
    ],
    [customerList, openWebsiteInBrowserHandler],
  );

  const rowClickHandler = (_: SyntheticEvent, row: LastRunWebsiteTask) => {
    navigateToWebsitePage("websites", row.website);
  };

  return (
    <CustomReactTable<LastRunWebsiteTask>
      data={lastRunWebsiteTaskList}
      columns={defaultColumns}
      rowClickHandler={rowClickHandler}
      isLoadingDataList={isLoading}
      hasNextData={hasNextPage}
      onClickLoadMore={() => fetchNextPage()}
      onClickLoadAll={() => fetchNextPage(true)}
      selectable={false}
    />
  );
};
