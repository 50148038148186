import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useTaskRead } from "hooks";
import { Task } from "types";
import {
  CustomAvatar,
  CustomButton,
  CustomTag,
  LabeledTypography,
  Loading,
} from "core";
import { CheckIcon, CrossIcon, DeleteIcon, EditIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { Routes } from "routes";
import { TaskDeleteDialog } from "../../taskDelete/TaskDeleteDialog";
import utils from "utils";
import useStyles from "./styles";

export const TaskPage: React.FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { task, isLoading, employee } = useTaskRead();

  const [deleteTaskDialogOpen, setDeleteTaskDialogOpen] =
    useState<boolean>(false);
  const [taskToDelete, setTaskToDelete] = useState<Task | undefined>();

  if (isLoading) {
    return (
      <BoxHeadlineContainer boxTitle="Pauschalaufgabe Details">
        <Loading
          description="Bitte warten. Pauschalaufgabe wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  if (task === undefined || task === null) {
    return (
      <BoxHeadlineContainer boxTitle="Pauschalaufgabe Details">
        Pauschalaufgabe nicht verfügbar!
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={
        !isLoading && task ? task.taskType.name : "Pauschalaufgabe-Details"
      }
      marginTop={false}
      boxMenu={
        <>
          <CustomButton
            text="Bearbeiten"
            iconBefore={<EditIcon />}
            onClick={() =>
              navigate(
                Routes.tasks.subNavigations.task.subNavigations.taskedit.path.replace(
                  ":taskID",
                  task.id,
                ),
              )
            }
            size="small"
            color="blue"
            rootClassName={classes.editButton}
            accessKey="e"
          />
          <CustomButton
            text="Löschen"
            iconBefore={<DeleteIcon />}
            onClick={() => {
              setTaskToDelete(task);
              setDeleteTaskDialogOpen(true);
            }}
            size="small"
            color="red"
            accessKey="d"
          />
        </>
      }
    >
      <TaskDeleteDialog
        dialogOpen={deleteTaskDialogOpen}
        setDialogOpen={setDeleteTaskDialogOpen}
        task={taskToDelete}
      />
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography
            label="Aufgaben-Typ"
            content={task.taskType?.name}
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography label="Webseite" content={task.website?.url} />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography label="Aufwand in m" content={task.effort} />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography
            label="Datum der Ausführung"
            content={
              task.date
                ? utils.dates.getDateInGermanFromAWSDateFormat(task.date)
                : null
            }
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Uhrzeit der Ausführung"
            content={
              task.time
                ? utils.dates.getTimeInGermanFromAWSTimeFormat(task.time) +
                  " Uhr"
                : null
            }
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography
            label="Frist-Datum"
            content={utils.dates.getDateInGermanFromAWSDateFormat(
              task.deadlineDate,
            )}
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Frist-Uhrzeit"
            content={
              utils.dates.getTimeInGermanFromAWSTimeFormat(task.deadlineTime) +
              " Uhr"
            }
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography
            label="Abrechnungsart"
            content={
              task.billType === "free"
                ? "Gratis"
                : task.billType === "maintenance"
                  ? "Pflege"
                  : "Rechnung"
            }
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Abgerechnet?"
            content={task.billed ? <CheckIcon /> : <CrossIcon />}
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Status"
            content={
              <CustomTag
                content={
                  task.status === "open"
                    ? "Offen"
                    : task.status === "inprogress"
                      ? "In Bearbeitung"
                      : "Fertig"
                }
                tagSize="small"
                tagStyle="outlined"
                tagColor={
                  task.status === "open"
                    ? "orange"
                    : task.status === "inprogress"
                      ? "blue"
                      : "green"
                }
              />
            }
          />
        </Grid>
      </Grid>
      {employee ? (
        <>
          <Typography variant="h3">Mitarbeiter</Typography>
          <Grid
            className={classes.gridRow}
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            {employee.photoURL && (
              <Grid item>
                <CustomAvatar
                  showBadge={false}
                  size="100px"
                  alternativeImage={employee.photoURL}
                />
              </Grid>
            )}
            <Grid item justifyContent="flex-start">
              <Typography variant="h4" className={classes.assigneeName}>
                {employee.firstName + " " + employee.lastName}
              </Typography>
              <a className={classes.email} href={`mailto:${employee.emailId}`}>
                {employee.emailId}
              </a>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid container direction="row" className={classes.gridRow}>
          <Grid item md={4}>
            <LabeledTypography label="Mitarbeiter" content={task.employee} />
          </Grid>
        </Grid>
      )}
    </BoxHeadlineContainer>
  );
};
