import moment from "moment";
import { QueryKeys, client, queryClient } from "queryClient";
import { Ticket } from "types";
import utils from "utils";

export const getTicketList = async (
  from: number | null,
  loadAll?: boolean,
): Promise<{ ticketList: Ticket[]; nextFrom: number | null }> => {
  const { data, errors } = await client.queries.listTickets({
    all: loadAll ?? false,
    limit: 100,
    from: from ?? 0,
    token: utils.zoho.getCurrentToken("ticketsToken"),
  });

  if (errors) {
    throw errors[0];
  }

  if (!data) {
    return {
      ticketList: [],
      nextFrom: null,
    };
  }

  if (data.isNewToken) {
    utils.zoho.setCurrentToken("ticketsToken", data.token);
  }

  return {
    ticketList: data.ticketList,
    nextFrom:
      from !== null && data.ticketList.length === 200 ? from + 200 : null,
  };
};

export const getAllTicketList = async (): Promise<{
  ticketList: Ticket[];
  nextFrom: number | null;
}> => {
  const { data, errors } = await client.queries.listTickets({
    all: true,
    limit: 100,
    from: 0,
    token: utils.zoho.getCurrentToken("ticketsToken"),
  });

  if (errors) {
    throw errors[0];
  }

  if (!data) {
    return {
      ticketList: [],
      nextFrom: null,
    };
  }

  if (data.isNewToken) {
    utils.zoho.setCurrentToken("ticketsToken", data.token);
  }

  return {
    ticketList: data.ticketList,
    nextFrom: null,
  };
};

export const getTicketListByDomain = async (
  domain: string,
  dateFrom: Date,
  dateTo: Date,
): Promise<Ticket[]> => {
  const { data, errors } = await client.queries.listClosedTicketsByDomain({
    domain,
    dateFrom: moment(dateFrom).format("YYYY-MM-DD"),
    dateTo: moment(dateTo).format("YYYY-MM-DD"),
    token: utils.zoho.getCurrentToken("ticketsToken"),
  });

  if (errors) {
    throw errors[0];
  }

  if (!data) {
    return [];
  }

  if (data.isNewToken) {
    utils.zoho.setCurrentToken("ticketsToken", data.token);
  }

  return data.ticketList;
};

export const getTicketListByIDs = async (ids: string): Promise<Ticket[]> => {
  const { data, errors } = await client.queries.listTicketsByIDs({
    ids,
    token: utils.zoho.getCurrentToken("ticketsToken"),
  });

  if (errors) {
    throw errors[0];
  }

  if (!data) {
    return [];
  }

  if (data.isNewToken) {
    utils.zoho.setCurrentToken("ticketsToken", data.token);
  }

  return data.ticketList;
};

export const getTicket = async (ticketID: string): Promise<Ticket | null> => {
  const { data, errors } = await client.queries.getTicket({
    ticketID,
    token: utils.zoho.getCurrentToken("ticketsToken"),
  });

  if (errors) {
    throw errors[0];
  }

  if (!data) {
    return null;
  }

  if (data.isNewToken) {
    utils.zoho.setCurrentToken("ticketsToken", data.token);
  }

  return data.ticket ?? null;
};

export const setTicketsAsBilled = async (
  ids: string[],
  billed: boolean,
): Promise<Ticket[]> => {
  if (ids.length === 0) {
    return [];
  }

  const { data, errors } = await client.queries.setTicketsAsBilled({
    ids,
    billed,
    token: utils.zoho.getCurrentToken("ticketsToken"),
  });

  if (errors) {
    throw errors[0];
  }

  if (!data) {
    return [];
  }

  if (data.isNewToken) {
    utils.zoho.setCurrentToken("ticketsToken", data.token);
  }

  const previousTickets: Ticket[] | undefined = queryClient.getQueryData([
    QueryKeys.Tickets,
  ]);

  if (previousTickets) {
    queryClient.setQueryData(
      [QueryKeys.Tickets],
      previousTickets.map((ticket) => {
        if (ids.includes(ticket.id)) {
          return {
            ...ticket,
            cf_abgerechnet: billed ? "true" : "false",
          };
        }

        return ticket;
      }),
    );
  }

  for (let i = 0; i < ids.length; i++) {
    const previousTicket: Ticket | undefined = queryClient.getQueryData([
      QueryKeys.Tickets,
      ids[i],
    ]);

    if (previousTicket) {
      queryClient.setQueryData([QueryKeys.Tickets, ids[i]], {
        ...previousTicket,
        cf_abgerechnet: billed ? "true" : "false",
      });
    }
  }

  return data.ticketList;
};
