import { Schema } from "queryClient";
import { TypeKeysEnum } from "./General.types";

export type Customer = Schema["Customer"]["type"];

export const CustomerTypeKeys: TypeKeysEnum<Customer> = {
  id: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.id,
  },
  Kundennummer: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.Kundennummer,
  },
  Account_Name: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.Account_Name,
  },
  Website: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.Website ?? "",
  },
  Rechnungsempf_nger: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.Rechnungsempf_nger ?? "",
  },
  Phone: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: true,
    formatter: (data) => data.Phone ?? "",
  },
  Mobil: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: true,
    formatter: (data) => data.Mobil ?? "",
  },
  Firmen_E_Mail: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.Firmen_E_Mail ?? "",
  },
  Billing_City: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.Billing_City ?? "",
  },
  websiteAmount: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.websiteAmount.toString(),
  },
};

export const isKeyOfCustomer = (key: string): key is keyof Customer =>
  key in CustomerTypeKeys;
