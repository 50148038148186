import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  SearchTextParamName,
  SortDirection,
  TaskType,
  TaskTypeTypeKeys,
  isKeyOfTaskType,
} from "types";
import {
  FilterField,
  filterEntities,
  filterEntityByField,
  getComparisonType,
  getSearchFields,
  getSortFields,
  sortEntities,
  sortEntitiesByField,
} from "utils/tables";

const searchFields = getSearchFields<TaskType>(TaskTypeTypeKeys);

export const useTaskTypeListFilterAndSort = (filter = true) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<TaskType>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof TaskType, string>> = {};

    const filters: FilterField<TaskType>[] = Array.from(
      searchParams.entries(),
    ).map(([field, value]) => ({
      field: field as keyof TaskType,
      value,
      comparisonType: getComparisonType(searchParams, field),
    }));

    const defaultFilterFields: FilterField<TaskType>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof TaskType,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof TaskType, SortDirection>> = {
      name: SortDirection.asc,
    };

    return getSortFields<TaskType>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfTaskType,
    );
  }, [searchParams]);

  const filterAndSortTaskTypes = useCallback(
    (
      taskTypeList: TaskType[],
      customSortFunctions: Partial<
        Record<
          keyof TaskType,
          (a: TaskType, b: TaskType, direction: SortDirection) => number
        >
      > = {},
      customFilterFunctions: Partial<
        Record<keyof TaskType, (entity: TaskType, value: string) => boolean>
      > = {},
    ) =>
      sortEntities<TaskType>(
        filter
          ? filterEntities<TaskType>(
              taskTypeList,
              filterFields,
              searchFields,
              searchParams.get(SearchTextParamName.SearchTaskType),
              filterEntityByField<TaskType>,
              TaskTypeTypeKeys,
              customFilterFunctions,
            )
          : taskTypeList,
        sortFields,
        sortEntitiesByField<TaskType>,
        TaskTypeTypeKeys,
        customSortFunctions,
      ).map((taskType) => ({
        ...taskType,
        updatedAt: new Date(
          new Date(taskType.updatedAt).getTime() + 1000,
        ).toISOString(),
      })),
    [filter, filterFields, searchParams, sortFields],
  );

  return filterAndSortTaskTypes;
};
