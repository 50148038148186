import { client } from "queryClient";
import { PermanentTask, PermanentTaskSelectionSet } from "types";

export const getPermanentTaskList = async (
  nextTokenParam: string | null = null,
): Promise<{
  permanentTaskList: PermanentTask[];
  nextToken: string | null;
}> => {
  const { data, nextToken } = await client.models.PermanentTask.list({
    limit: 100,
    nextToken: nextTokenParam,
    selectionSet: PermanentTaskSelectionSet,
  });

  return {
    permanentTaskList: data ?? [],
    nextToken: nextToken ?? null,
  };
};

export const getAllPermanentTaskList = async (
  nextTokenParam: string | null = null,
  prevPermanentTaskList: PermanentTask[] = [],
): Promise<{
  permanentTaskList: PermanentTask[];
  nextToken: string | null;
}> => {
  const { data, nextToken } = await client.models.PermanentTask.list({
    limit: 200,
    nextToken: nextTokenParam,
    selectionSet: PermanentTaskSelectionSet,
  });

  const permanentTaskList = [...prevPermanentTaskList, ...data];

  return nextToken
    ? getAllPermanentTaskList(nextToken, permanentTaskList)
    : { permanentTaskList, nextToken: null };
};

export const getPermanentTask = async (
  id: string,
): Promise<PermanentTask | null> => {
  const { data } = await client.models.PermanentTask.get(
    {
      id,
    },
    {
      selectionSet: PermanentTaskSelectionSet,
    },
  );

  return data;
};
