import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import { useSystemRead } from "hooks";
import { System } from "types";
import { CustomButton, LabeledTypography, Loading } from "core";
import { DeleteIcon, DescriptionFormatter, EditIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { Routes } from "routes";
import { SystemDeleteDialog } from "../../systemDelete/SystemDeleteDialog";
import useStyles from "./styles";

export const SystemPage: React.FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { system, isLoading } = useSystemRead();

  const [deleteSystemDialogOpen, setDeleteSystemDialogOpen] =
    useState<boolean>(false);
  const [systemToDelete, setSystemToDelete] = useState<System | undefined>();

  if (isLoading) {
    return (
      <BoxHeadlineContainer boxTitle="System Details">
        <Loading
          description="Bitte warten. System wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  if (system === undefined || system === null) {
    return (
      <BoxHeadlineContainer boxTitle="System Details">
        System nicht verfügbar!
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={!isLoading && system ? system.name : "System-Details"}
      marginTop={false}
      boxMenu={
        <>
          <CustomButton
            text="Bearbeiten"
            iconBefore={<EditIcon />}
            onClick={() =>
              navigate(
                Routes.systems.subNavigations.system.subNavigations.systemedit.path.replace(
                  ":systemID",
                  system.id,
                ),
              )
            }
            size="small"
            color="blue"
            rootClassName={classes.editButton}
            accessKey="e"
          />
          <CustomButton
            text="Löschen"
            iconBefore={<DeleteIcon />}
            onClick={() => {
              setSystemToDelete(system);
              setDeleteSystemDialogOpen(true);
            }}
            size="small"
            color="red"
            accessKey="d"
          />
        </>
      }
    >
      <SystemDeleteDialog
        dialogOpen={deleteSystemDialogOpen}
        setDialogOpen={setDeleteSystemDialogOpen}
        system={systemToDelete}
      />
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography label="Name" content={system.name} />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography label="Bewertung" content={system.rating} />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography label="Komplexität" content={system.complexity} />
        </Grid>
      </Grid>
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item>
          <Typography variant="h3">Beschreibung</Typography>
          <DescriptionFormatter description={system.description} />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};
