import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { AuthContext } from "context";
import {
  useTaskTypeCreate,
  useTaskTypeRead,
  useTaskTypeRoutes,
  useTaskTypeUpdate,
} from "hooks";
import { CustomButton } from "core";
import { Loading } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { Routes } from "routes";
import { TaskTypeForm, TaskTypeFormHandles } from "../TaskTypeForm";
import useStyles from "./styles";

type TaskTypeFormPageProps = {
  formIntent: "create" | "edit";
};

export const TaskTypeFormPage: React.FC<TaskTypeFormPageProps> = ({
  formIntent,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const { taskType, isLoading } = useTaskTypeRead();
  const createTaskTypeMutation = useTaskTypeCreate();
  const updateTaskTypeMutation = useTaskTypeUpdate();
  const { navigateToTaskTypePage } = useTaskTypeRoutes();

  const taskTypeFormRef = useRef<TaskTypeFormHandles>(null);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitTaskType = async () => {
    const taskTypeFormInput = taskTypeFormRef.current?.validateTaskTypeForm();
    if (!taskTypeFormInput) {
      console.error("TaskTypeForm is invalid...");
      return;
    }

    if (!authContext.user) {
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } eines Aufgaben-Typs ist ein Fehler aufgetreten.`,
      );
    }

    setSubmitLoading(true);

    console.log("taskTypeInput: ", taskTypeFormInput);

    const mutateTaskTypeRequest = taskType
      ? await updateTaskTypeMutation.mutateAsync({
          ...taskTypeFormInput,
          id: taskType.id,
        })
      : await createTaskTypeMutation.mutateAsync(taskTypeFormInput);

    if (!mutateTaskTypeRequest) {
      setSubmitLoading(false);
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } eines Aufgaben-Typs ist ein Fehler aufgetreten.`,
      );
    }

    console.log("mutateTaskTypeRequest: ", mutateTaskTypeRequest);

    setSubmitLoading(false);
    navigateToTaskTypePage(mutateTaskTypeRequest);
  };

  if (isLoading) {
    return (
      <BoxHeadlineContainer
        boxTitle={
          "Aufgaben-Typ " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
        }
      >
        <Loading
          description="Bitte warten. Aufgaben-Typ wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={
        "Aufgaben-Typ " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
      }
    >
      <TaskTypeForm
        taskType={taskType}
        formIntent={formIntent}
        ref={taskTypeFormRef}
      />

      <Grid container direction="row" className={classes.buttons}>
        <Grid item className={classes.gridItem}>
          <CustomButton
            text={
              "Aufgaben-Typ " +
              (formIntent === "edit" ? "speichern" : "anlegen")
            }
            onClick={() => submitTaskType()}
            loading={submitLoading}
            accessKey="s"
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <CustomButton
            color="red"
            text="Abbrechen"
            accessKey="s"
            onClick={() => {
              navigate(Routes.tasktypes.path);
            }}
            disabled={submitLoading}
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};
