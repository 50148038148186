import React, { SyntheticEvent, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";
import { useCustomerAllList, useWebsiteRoutes } from "hooks";
import { Website } from "types";
import { CustomReactTable } from "core";
import { ActionsColumnFormatter } from "modules/shared/columnformatters/ActionsColumnFormatter";
import { getBacklinkStatusName } from "modules/shared/options/BacklinkStatusOption";
import { WebsiteDeleteDialog } from "../../websiteDelete/WebsiteDeleteDialog";

type WebsiteListTableProps = {
  routeKey: "websites" | "customerwebsites" | "templatewebsites";
  listIntent: "list" | "selection";
  websiteList: Website[];
  isLoading: boolean;
  hasNextPage: boolean;
  fetchNextPage: (loadAll?: boolean) => void;
  websiteSelectionHandler?: (website: Website) => void;
};

export const WebsiteListTable: React.FC<WebsiteListTableProps> = ({
  routeKey,
  listIntent,
  websiteList,
  isLoading,
  hasNextPage,
  fetchNextPage,
  websiteSelectionHandler,
}) => {
  const { customerList } = useCustomerAllList(false);

  const {
    navigateToWebsitePage,
    navigateToEditWebsitePage,
    openWebsiteInBrowserHandler,
  } = useWebsiteRoutes();

  const [deleteWebsiteDialogOpen, setDeleteWebsiteDialogOpen] =
    useState<boolean>(false);
  const [websiteToDelete, setWebsiteToDelete] = useState<Website | undefined>();

  const defaultColumns = React.useMemo<ColumnDef<Website>[]>(
    () => [
      {
        header: "URL",
        accessorKey: "url",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
        meta: {
          isDefaultSort: true,
          defaultSortOrder: "asc",
        },
      },
      {
        header: "Besitzer",
        accessorKey: "websiteOwner",
        cell: ({ row }) =>
          customerList.find(
            (customerItem) => customerItem.id === row.original.websitePayer,
          )?.Account_Name,
        footer: (props) => props.column.id,
      },
      {
        header: "Zahler",
        accessorKey: "websitePayer",
        cell: ({ row }) =>
          customerList.find(
            (customerItem) => customerItem.id === row.original.websitePayer,
          )?.Account_Name,
        footer: (props) => props.column.id,
      },
      {
        header: "Website-Typ",
        accessorKey: "websiteType",
        accessorFn: (row) =>
          row.websiteType === "website"
            ? "Webseite"
            : row.websiteType === "webshop"
              ? "Webshop"
              : row.websiteType === "application"
                ? "Anwendung"
                : "Server",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "System",
        accessorKey: "systemID",
        accessorFn: (row) => row.system.name,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Hoster",
        accessorKey: "hosterID",
        accessorFn: (row) => row.hoster.name,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Template",
        accessorKey: "templateID",
        accessorFn: (row) => row.template.name,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Zuständig",
        accessorKey: "currentlyResponsible",
        accessorFn: (row) => (row.currentlyResponsible ? "Ja" : "Nein"),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Von uns erstellt",
        accessorKey: "createdByUs",
        accessorFn: (row) => (row.createdByUs ? "Ja" : "Nein"),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "GW Backlink Erstellung",
        accessorKey: "backlinkErstellung",
        accessorFn: (row) => getBacklinkStatusName(row.backlinkErstellung),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "GW Backlink Pflege",
        accessorKey: "backlinkPflege",
        accessorFn: (row) => getBacklinkStatusName(row.backlinkPflege),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Formilo Backlink",
        accessorKey: "formiloBacklink",
        accessorFn: (row) => getBacklinkStatusName(row.formiloBacklink),
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "PHP-Version",
        accessorKey: "phpVersion",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Aufwandsdefinitionen",
        accessorKey: "effortDefinitions",
        accessorFn: (row) => row.effortDefinitions.length,
        enableSorting: false,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Aktionen",
        cell: ({ row }) => (
          <ActionsColumnFormatter<Website>
            row={row}
            listIntent={listIntent}
            openEditRowPage={(row) => navigateToEditWebsitePage(routeKey, row)}
            setRowToDelete={setWebsiteToDelete}
            setDeleteRowDialogOpen={setDeleteWebsiteDialogOpen}
            rowSelectionHandler={websiteSelectionHandler}
            openInBrowserHandler={openWebsiteInBrowserHandler}
            minWidth={150}
          />
        ),
        footer: (props) => props.column.id,
      },
    ],
    [
      navigateToEditWebsitePage,
      websiteSelectionHandler,
      openWebsiteInBrowserHandler,
      listIntent,
      customerList,
    ],
  );

  const rowClickHandler = (_: SyntheticEvent, row: Website) => {
    if (websiteSelectionHandler) {
      websiteSelectionHandler(row);
    } else {
      navigateToWebsitePage(routeKey, row);
    }
  };

  return (
    <>
      <WebsiteDeleteDialog
        dialogOpen={deleteWebsiteDialogOpen}
        setDialogOpen={setDeleteWebsiteDialogOpen}
        website={websiteToDelete}
        routeKey={routeKey}
      />
      <CustomReactTable<Website>
        data={websiteList}
        columns={defaultColumns}
        rowClickHandler={rowClickHandler}
        isLoadingDataList={isLoading}
        hasNextData={hasNextPage}
        onClickLoadMore={() => fetchNextPage()}
        onClickLoadAll={() => fetchNextPage(true)}
        selectable={false}
      />
    </>
  );
};
