import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { CreatePermanentTaskInput, PermanentTask } from "types";
import { getPermanentTask } from "modules/permanenttasks/api";

export const usePermanentTaskCreate = () => {
  const createMutation = useMutation({
    mutationFn: async (input: CreatePermanentTaskInput) => {
      const { data: newPermanentTask } =
        await client.models.PermanentTask.create(input);

      if (!newPermanentTask) return null;

      const permanentTask = await getPermanentTask(newPermanentTask.id);

      return permanentTask;
    },
    // When mutate is called:
    onMutate: async (newPermanentTask) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [QueryKeys.PermanentTasks] });

      // Snapshot the previous value
      const previousPermanentTasks = queryClient.getQueryData([
        QueryKeys.PermanentTasks,
      ]);

      // Optimistically update to the new value
      if (previousPermanentTasks) {
        queryClient.setQueryData(
          [QueryKeys.PermanentTasks],
          (old: PermanentTask[]) => [...old, newPermanentTask],
        );
      }

      // Return a context object with the snapshotted value
      return { previousPermanentTasks };
    },
    // If the mutation fails,
    // use the context returned from onMutate to rollback
    onError: (err, newPermanentTask, context) => {
      console.error("Error saving record:", err, newPermanentTask);
      if (context?.previousPermanentTasks) {
        queryClient.setQueryData(
          [QueryKeys.PermanentTasks],
          context.previousPermanentTasks,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.PermanentTasks] });
    },
  });

  return createMutation;
};
