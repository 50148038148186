import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  ReportEntry,
  ReportEntryTypeKeys,
  SearchTextParamName,
  SortDirection,
  isKeyOfReportEntry,
} from "types";
import {
  FilterField,
  filterEntities,
  filterEntityByField,
  getComparisonType,
  getSearchFields,
  getSortFields,
  sortEntities,
  sortEntitiesByField,
} from "utils/tables";

const searchFields = getSearchFields<ReportEntry>(ReportEntryTypeKeys);

export const useReportEntryListFilterAndSort = (filter = true) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<ReportEntry>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof ReportEntry, string>> = {};

    const filters: FilterField<ReportEntry>[] = Array.from(
      searchParams.entries(),
    ).map(([field, value]) => ({
      field: field as keyof ReportEntry,
      value,
      comparisonType: getComparisonType(searchParams, field),
    }));

    const defaultFilterFields: FilterField<ReportEntry>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof ReportEntry,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof ReportEntry, SortDirection>> = {
      originalType: SortDirection.asc,
    };

    return getSortFields<ReportEntry>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfReportEntry,
    );
  }, [searchParams]);

  const filterAndSortReportEntrys = useCallback(
    (
      hosterList: ReportEntry[],
      customSortFunctions: Partial<
        Record<
          keyof ReportEntry,
          (a: ReportEntry, b: ReportEntry, direction: SortDirection) => number
        >
      > = {},
      customFilterFunctions: Partial<
        Record<
          keyof ReportEntry,
          (entity: ReportEntry, value: string) => boolean
        >
      > = {},
    ) =>
      sortEntities<ReportEntry>(
        filter
          ? filterEntities<ReportEntry>(
              hosterList,
              filterFields,
              searchFields,
              searchParams.get(SearchTextParamName.SearchReportEntry),
              filterEntityByField<ReportEntry>,
              ReportEntryTypeKeys,
              customFilterFunctions,
            )
          : hosterList,
        sortFields,
        sortEntitiesByField<ReportEntry>,
        ReportEntryTypeKeys,
        customSortFunctions,
      ).map((hoster) => ({
        ...hoster,
        updatedAt: new Date(
          new Date(hoster.updatedAt).getTime() + 1000,
        ).toISOString(),
      })),
    [filter, filterFields, searchParams, sortFields],
  );

  return filterAndSortReportEntrys;
};
