import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { AuthContext } from "context";
import {
  useTaskCreate,
  useTaskRead,
  useTaskRoutes,
  useTaskUpdate,
} from "hooks";
import { CustomButton } from "core";
import { Loading } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { Routes } from "routes";
import { TaskForm, TaskFormHandles } from "../TaskForm";
import useStyles from "./styles";

type TaskFormPageProps = {
  formIntent: "create" | "edit";
};

export const TaskFormPage: React.FC<TaskFormPageProps> = ({ formIntent }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const { task, isLoading } = useTaskRead();
  const createTaskMutation = useTaskCreate();
  const updateTaskMutation = useTaskUpdate();
  const { navigateToTaskPage } = useTaskRoutes();

  const taskFormRef = useRef<TaskFormHandles>(null);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitTask = async () => {
    const taskFormInput = taskFormRef.current?.validateTaskForm();
    if (!taskFormInput) {
      console.error("TaskForm is invalid...");
      return;
    }

    if (!authContext.user) {
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } einer Pauschalaufgabe ist ein Fehler aufgetreten.`,
      );
    }

    setSubmitLoading(true);

    console.log("taskInput: ", taskFormInput);

    const mutateTaskRequest = task
      ? await updateTaskMutation.mutateAsync({ ...taskFormInput, id: task.id })
      : await createTaskMutation.mutateAsync(taskFormInput);

    if (!mutateTaskRequest) {
      setSubmitLoading(false);
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } einer Pauschalaufgabe ist ein Fehler aufgetreten.`,
      );
    }

    console.log("mutateTaskRequest: ", mutateTaskRequest);

    setSubmitLoading(false);
    navigateToTaskPage(mutateTaskRequest);
  };

  if (isLoading) {
    return (
      <BoxHeadlineContainer
        boxTitle={
          "Pauschalaufgabe " +
          (formIntent === "edit" ? "bearbeiten" : "anlegen")
        }
      >
        <Loading
          description="Bitte warten. Pauschalaufgabe wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={
        "Pauschalaufgabe " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
      }
    >
      <TaskForm task={task} formIntent={formIntent} ref={taskFormRef} />

      <Grid container direction="row" className={classes.buttons}>
        <Grid item className={classes.gridItem}>
          <CustomButton
            text={
              "Pauschalaufgabe " +
              (formIntent === "edit" ? "speichern" : "anlegen")
            }
            onClick={() => submitTask()}
            loading={submitLoading}
            accessKey="s"
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <CustomButton
            color="red"
            text="Abbrechen"
            accessKey="a"
            onClick={() => {
              navigate(Routes.tasks.path);
            }}
            disabled={submitLoading}
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};
