import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  SearchTextParamName,
  SortDirection,
  User,
  UserTypeKeys,
  isKeyOfUser,
} from "types";
import {
  FilterField,
  filterEntities,
  filterEntityByField,
  getComparisonType,
  getSearchFields,
  getSortFields,
  sortEntities,
  sortEntitiesByField,
} from "utils/tables";

const searchFields = getSearchFields<User>(UserTypeKeys);

export const useUserListFilterAndSort = (filter = true) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<User>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof User, string>> = {};

    const filters: FilterField<User>[] = Array.from(searchParams.entries()).map(
      ([field, value]) => ({
        field: field as keyof User,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }),
    );

    const defaultFilterFields: FilterField<User>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof User,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof User, SortDirection>> = {
      firstName: SortDirection.asc,
    };

    return getSortFields<User>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfUser,
    );
  }, [searchParams]);

  const filterAndSortUsers = useCallback(
    (
      userList: User[],
      customSortFunctions: Partial<
        Record<
          keyof User,
          (a: User, b: User, direction: SortDirection) => number
        >
      > = {},
      customFilterFunctions: Partial<
        Record<keyof User, (entity: User, value: string) => boolean>
      > = {},
    ) =>
      sortEntities<User>(
        filter
          ? filterEntities<User>(
              userList,
              filterFields,
              searchFields,
              searchParams.get(SearchTextParamName.SearchUser),
              filterEntityByField<User>,
              UserTypeKeys,
              customFilterFunctions,
            )
          : userList,
        sortFields,
        sortEntitiesByField<User>,
        UserTypeKeys,
        customSortFunctions,
      ).map((user) => ({
        ...user,
        updatedAt: new Date(
          new Date(user.updatedAt).getTime() + 1000,
        ).toISOString(),
      })),
    [filter, filterFields, searchParams, sortFields],
  );

  return filterAndSortUsers;
};
