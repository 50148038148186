import { useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { Hoster } from "types";
import { getAllHosterList, getHosterList } from "modules/hosters/api";
import { useHosterListFilterAndSort } from "../useHosterListFilterAndSort";

export const useHosterList = (filter = true) => {
  const filterAndSortHosters = useHosterListFilterAndSort(filter);

  const [loadAll, setLoadAll] = useState(false);

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<{
    hosterList: Hoster[];
    nextToken: string | null;
  }>({
    queryKey: loadAll ? [QueryKeys.Hosters, "all"] : [QueryKeys.Hosters],
    queryFn: ({ pageParam }) =>
      loadAll ? getAllHosterList() : getHosterList(pageParam as string | null),
    getNextPageParam: (lastPage) => lastPage.nextToken ?? undefined,
    initialPageParam: null,
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const hosterList = useMemo(() => {
    const hosters = data?.pages
      ? data.pages.flatMap((page) => page.hosterList)
      : [];

    return filterAndSortHosters(hosters);
  }, [data, filterAndSortHosters]);

  return {
    hosterList,
    fetchNextPage: (all?: boolean) => {
      if (all) setLoadAll(true);
      fetchNextPage();
    },
    hasNextPage,
    isLoading,
  };
};
