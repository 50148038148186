import { client } from "queryClient";
import {
  Booking,
  ReportEntry,
  ReportEntrySelectionSet,
  Task,
  Ticket,
  Website,
} from "types";
import { getClosedTaskListByWebsiteAndDate } from "modules/tasks/api";
import { getTicketListByDomain } from "modules/tickets/api";
import { getWebsiteListByCustomer } from "modules/websites/api";
import utils from "utils";

export type WebsiteReportType = {
  website: Website;
  taskList: Task[];
  ticketList: Ticket[];
};

export const getReportEntryListByBookingID = async (
  bookingID: string | undefined,
  nextTokenParam: string | null = null,
  prevReportEntryList: ReportEntry[] = [],
): Promise<{
  reportEntryList: ReportEntry[];
  nextToken: string | null;
}> => {
  if (!bookingID) {
    return {
      reportEntryList: [],
      nextToken: null,
    };
  }

  const { data, nextToken } =
    await client.models.ReportEntry.reportEntriesbyBooking(
      {
        bookingID,
      },
      {
        limit: 500,
        nextToken: nextTokenParam,
        selectionSet: ReportEntrySelectionSet,
      },
    );

  const reportEntryList = [...prevReportEntryList, ...data];

  return nextToken
    ? getReportEntryListByBookingID(bookingID, nextToken, reportEntryList)
    : { reportEntryList, nextToken: null };
};

export const getReportEntryListByTaskID = async (
  taskID: string | undefined,
  nextTokenParam: string | null = null,
  prevReportEntryList: ReportEntry[] = [],
): Promise<{ reportEntryList: ReportEntry[]; nextToken: string | null }> => {
  if (!taskID) {
    return {
      reportEntryList: [],
      nextToken: null,
    };
  }

  const { data, nextToken } =
    await client.models.ReportEntry.reportEntriesbyTask(
      {
        taskID,
      },
      {
        limit: 500,
        nextToken: nextTokenParam,
        selectionSet: ReportEntrySelectionSet,
      },
    );

  const reportEntryList = [...prevReportEntryList, ...data];

  return nextToken
    ? getReportEntryListByTaskID(taskID, nextToken, reportEntryList)
    : { reportEntryList, nextToken: null };
};

export const getBookingsWebsiteReports = async (
  booking: Booking,
): Promise<WebsiteReportType[]> => {
  const careReportEntries: WebsiteReportType[] = [];
  const websiteList = await getWebsiteListByCustomer(booking.customerID);

  const firstDayOfMonth = utils.dates.getFirstDayOfMonthInAWSDateFormat(
    booking.year,
    booking.month, // 1 is january
  );
  const lastDayOfMonth = utils.dates.getLastDayOfMonthInAWSDateFormat(
    booking.year,
    booking.month, // 1 is january
  );

  for (let website of websiteList) {
    const [taskList, ticketList] = await Promise.all([
      getClosedTaskListByWebsiteAndDate(
        website.id,
        utils.dates.convertDateToAWSDateFormat(firstDayOfMonth),
        utils.dates.convertDateToAWSDateFormat(lastDayOfMonth),
      ),
      getTicketListByDomain(website.url, firstDayOfMonth, lastDayOfMonth),
    ]);

    const careReportEntry = {
      website: website,
      taskList: taskList,
      ticketList: ticketList,
    } as WebsiteReportType;

    careReportEntries.push(careReportEntry);
  }

  return careReportEntries;
};

export const getReportEntry = async (
  reportEntryID: string,
): Promise<ReportEntry | null> => {
  const { data } = await client.models.ReportEntry.get(
    {
      id: reportEntryID,
    },
    {
      selectionSet: ReportEntrySelectionSet,
    },
  );

  return data ?? null;
};
