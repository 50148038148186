import { useNavigate } from "react-router-dom";
import { Task } from "types";
import { Routes } from "routes";

export const useTaskRoutes = () => {
  const navigate = useNavigate();

  const getCreateTaskPageRoute = () =>
    Routes.tasks.subNavigations.taskcreate.path;

  const getEditTaskPageRoute = (task: Task) =>
    Routes.tasks.subNavigations.task.subNavigations.taskedit.path.replace(
      ":taskID",
      task.id,
    );

  const getTaskPageRoute = (task: Task) =>
    Routes.tasks.subNavigations.task.path.replace(":taskID", task.id);

  const getTaskListPageRoute = () => Routes.tasks.path;

  const navigateToCreateTaskPage = () => {
    navigate(getCreateTaskPageRoute());
  };

  const navigateToEditTaskPage = (task: Task) => {
    navigate(getEditTaskPageRoute(task));
  };

  const navigateToTaskPage = (task: Task) => {
    navigate(getTaskPageRoute(task));
  };

  const navigateToTaskListPage = () => {
    navigate(getTaskListPageRoute());
  };

  return {
    getCreateTaskPageRoute,
    getEditTaskPageRoute,
    getTaskPageRoute,
    navigateToCreateTaskPage,
    navigateToEditTaskPage,
    navigateToTaskPage,
    navigateToTaskListPage,
  };
};
