import React, { Suspense } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "context";
import { useUserActivityTracker } from "hooks";
import { Loading } from "core";
import { StateContainer } from "layout/StateContainer";
import {
  AuthRoutes,
  AuthRouting,
  RouteArrayType,
  RoutesArray,
  isAuthRoute,
} from "./routes";
import { AuthLayout } from "./layout/AuthLayout";
import { Layout } from "./layout/Layout";
import { NoRouteAccess } from "./layout/NoRouteAccess";
import { NoRouteMatch } from "./layout/NoRouteMatch";

const App: React.FC = () => {
  const authContext = useAuthContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useUserActivityTracker();

  const getRoutes = (subNavigations: RouteArrayType[]): JSX.Element[] => {
    return subNavigations.flatMap((route, i) => {
      const accessDenied =
        authContext.cognitoUser &&
        !route.groups.includes(authContext.cognitoUser.currentGroup.groupID);

      if (route.subNavigations.length) {
        return [
          <Route
            key={`private_route_${i}`}
            path={route.path}
            element={
              accessDenied ? (
                <NoRouteAccess groups={route.groups} />
              ) : (
                <StateContainer>{route.view}</StateContainer>
              )
            }
          />,
          ...getRoutes(route.subNavigations),
        ];
      } else {
        return [
          <Route
            key={`private_route_${i}`}
            path={route.path}
            element={
              accessDenied ? (
                <NoRouteAccess groups={route.groups} />
              ) : (
                <StateContainer>{route.view}</StateContainer>
              )
            }
          />,
        ];
      }
    });
  };

  if (!authContext.isLoading && authContext.isAuth && isAuthRoute(pathname)) {
    navigate("/");
    return <Layout>{null}</Layout>;
  }

  if (!authContext.isLoading && !authContext.isAuth && !isAuthRoute(pathname)) {
    navigate(AuthRoutes.login);
    return <Layout>{null}</Layout>;
  }

  return (
    <Layout>
      <Suspense
        fallback={
          <Loading description="Bitte warten. Die Seite wird geladen..." />
        }
      >
        <Routes>
          {getRoutes(RoutesArray)}

          {/* If user is logged in and reaches an authentication route then redirect him to home. */}
          {AuthRouting.map((route, i) => (
            <Route
              key={`route_${i}`}
              path={route.path}
              element={
                <StateContainer>
                  <AuthLayout>{route.view}</AuthLayout>
                </StateContainer>
              }
            />
          ))}

          {/* If user reaches an unknown route then redirect him to the error page NoRouteMatch. */}
          <Route key="nomatch" path="*" element={<NoRouteMatch />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default App;
