import { useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { System } from "types";
import { getAllSystemList, getSystemList } from "modules/systems/api";
import { useSystemListFilterAndSort } from "../useSystemListFilterAndSort";

export const useSystemList = (filter = true) => {
  const filterAndSortSystems = useSystemListFilterAndSort(filter);

  const [loadAll, setLoadAll] = useState(false);

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<{
    systemList: System[];
    nextToken: string | null;
  }>({
    queryKey: loadAll ? [QueryKeys.Systems, "all"] : [QueryKeys.Systems],
    queryFn: ({ pageParam }) =>
      loadAll ? getAllSystemList() : getSystemList(pageParam as string | null),
    getNextPageParam: (lastPage) => lastPage.nextToken ?? undefined,
    initialPageParam: null,
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const systemList = useMemo(() => {
    const systems = data?.pages
      ? data.pages.flatMap((page) => page.systemList)
      : [];

    return filterAndSortSystems(systems);
  }, [data, filterAndSortSystems]);

  return {
    systemList,
    fetchNextPage: (all?: boolean) => {
      if (all) setLoadAll(true);
      fetchNextPage();
    },
    hasNextPage,
    isLoading,
  };
};
