import React from "react";
import { Grid } from "@mui/material";
import { CustomButton } from "core";
import { BoxContainer } from "layout/Container/BoxContainer";
import useStyles from "./styles";

const Development: React.FC = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.dashboardPage}>
      <BoxContainer>
        {process.env.NODE_ENV === "development" && (
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={3}
          >
            <Grid item>
              <CustomButton text="Editionen aktualisieren" onClick={() => {}} />
            </Grid>
          </Grid>
        )}
      </BoxContainer>
    </div>
  );
};

export default Development;
