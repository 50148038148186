import { SelectionSet } from "aws-amplify/api";
import { Schema } from "queryClient";
import { TypeKeysEnum } from "./General.types";
import utils from "utils";

export const PermanentTaskSelectionSet = [
  "id",
  "owner",
  "createdAt",
  "updatedAt",
  "taskType.*",
  "taskTypeID",
  "website.*",
  "websiteID",
  "effort",
  "referenceDate",
  "periodType",
  "periodValue",
  "deadlineDays",
  "billType",
  "description",
] as const;

export type PermanentTask = SelectionSet<
  Schema["PermanentTask"]["type"],
  typeof PermanentTaskSelectionSet
>;

export type CreatePermanentTaskInput = Schema["PermanentTask"]["createType"];

export type UpdatePermanentTaskInput = Schema["PermanentTask"]["updateType"];

export type PeriodType = Schema["PeriodType"]["type"];

export const PermanentTaskTypeKeys: TypeKeysEnum<PermanentTask> = {
  id: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.id,
  },
  createdAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.createdAt) +
      " Uhr",
  },
  updatedAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.updatedAt) +
      " Uhr",
  },
  owner: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: (data) => data.owner ?? "",
  },
  taskType: {
    type: "entity",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  taskTypeID: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.taskTypeID,
  },
  website: {
    type: "entity",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  websiteID: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.websiteID,
  },
  effort: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.effort.toString(),
  },
  referenceDate: {
    type: "date",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateInGermanFromAWSDateFormat(data.referenceDate),
  },
  periodType: {
    type: "enum",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.periodType,
  },
  periodValue: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.periodValue.toString(),
  },
  deadlineDays: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.deadlineDays.toString(),
  },
  billType: {
    type: "enum",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.billType,
  },
  description: {
    type: "string",
    isArray: false,
    isRequired: false,
    isSortable: false,
    isSearchable: true,
    formatter: (data) => data.description ?? "",
  },
};

export const isKeyOfPermanentTask = (key: string): key is keyof PermanentTask =>
  key in PermanentTaskTypeKeys;
