import React from "react";
import { useWebsiteStatusList } from "hooks";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { Routes } from "routes";
import { WebsiteListFilter } from "../WebsiteStatusListFilter";
import { WebsiteListTable } from "../WebsiteStatusListTable";

export const WebsiteStatusListPage: React.FC = () => {
  const { lastRunWebsiteTaskList, isLoading, hasNextPage, fetchNextPage } =
    useWebsiteStatusList();

  return (
    <BoxHeadlineContainer
      boxTitle="Webseiten-Status"
      boxIcon={Routes.websitemanagement.subNavigations.websites.icon}
      boxSubTitle={lastRunWebsiteTaskList.length.toString()}
      isLoading={isLoading}
      paddingHorizontal={0}
      paddingVertical={0}
    >
      <WebsiteListFilter />
      <WebsiteListTable
        lastRunWebsiteTaskList={lastRunWebsiteTaskList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    </BoxHeadlineContainer>
  );
};
