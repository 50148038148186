import { useContext, useEffect, useMemo } from "react";
import { AuthContext } from "context";
import {
  useBookingRead,
  useCustomerAllList,
  useReportEntryListByBooking,
} from "hooks";

export const useLoadBookingDetails = () => {
  const authContext = useContext(AuthContext);

  const { booking, isLoading } = useBookingRead();
  const { customerList } = useCustomerAllList(false);
  const { reportEntryList } = useReportEntryListByBooking(false);

  const customer = useMemo(
    () =>
      customerList.find(
        (customerItem) => customerItem.id === booking?.customerID,
      ) ?? null,
    [customerList, booking],
  );

  useEffect(() => {
    if (!booking) return;

    authContext.setCurrentData({
      id: booking.id,
      name:
        (customer ? customer.Account_Name + " - " : "") +
        booking.month +
        "/" +
        booking.year,
    });

    authContext.setBooking(booking);
  }, []);

  return {
    booking,
    customer,
    isLoading,
    reportEntryList,
  };
};
