import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { CreateReportEntryInput, ReportEntry } from "types";
import { getReportEntry } from "modules/carereport/api";

export const useReportEntryCreate = () => {
  const createMutation = useMutation({
    mutationFn: async (input: CreateReportEntryInput) => {
      const { data: newReportEntry } =
        await client.models.ReportEntry.create(input);

      if (!newReportEntry) return null;

      const reportEntry = await getReportEntry(newReportEntry.id);

      return reportEntry;
    },
    // When mutate is called:
    onMutate: async (newReportEntry) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [QueryKeys.ReportEntries] });

      // Snapshot the previous value
      const previousReportEntries = queryClient.getQueryData([
        QueryKeys.ReportEntries,
      ]);

      // Optimistically update to the new value
      if (previousReportEntries) {
        queryClient.setQueryData(
          [QueryKeys.ReportEntries],
          (old: ReportEntry[]) => [...old, newReportEntry],
        );
      }

      // Return a context object with the snapshotted value
      return { previousReportEntries };
    },
    // If the mutation fails,
    // use the context returned from onMutate to rollback
    onError: (err, newReportEntry, context) => {
      console.error("Error saving record:", err, newReportEntry);
      if (context?.previousReportEntries) {
        queryClient.setQueryData(
          [QueryKeys.ReportEntries],
          context.previousReportEntries,
        );
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.ReportEntries] });
    },
  });

  return createMutation;
};
