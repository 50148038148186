import React from "react";
import { useNavigate } from "react-router-dom";
import { IconButton, Slide } from "@mui/material";
import { useAuthContext } from "context";
import useStyles from "./styles";

type HeaderLogoProps = {
  layoutType: "layout" | "authLayout";
};

export const HeaderLogo: React.FC<HeaderLogoProps> = ({
  layoutType = "layout",
}) => {
  const authContext = useAuthContext();
  const { classes, cx, theme } = useStyles({ layoutType });
  const navigate = useNavigate();

  return (
    <div
      className={cx(
        layoutType === "authLayout"
          ? classes.headerLogoAuthLayout
          : classes.headerLogoLayout,
        layoutType === "authLayout" || authContext.menuOpen
          ? null
          : classes.headerLogoSmall,
      )}
    >
      <IconButton
        classes={{
          root: cx(
            classes.logoButton,
            authContext.menuOpen ? null : classes.logoButtonSmall,
          ),
        }}
        onClick={() => navigate("/")}
      >
        <Slide
          in={!authContext.menuOpen && layoutType === "layout"}
          direction="left"
          easing={{
            enter: theme.transitions.easing.easeInOut,
            exit: theme.transitions.easing.easeInOut,
          }}
        >
          <img
            src="/logos/logo-default-icon.png"
            className={cx(
              classes.logoIcon,
              authContext.menuOpen ? null : classes.logoIconClosed,
            )}
            alt=""
          />
        </Slide>
        <Slide
          in={layoutType === "authLayout" || authContext.menuOpen}
          direction="right"
        >
          <img src="/logos/logo-default.png" className={classes.logo} alt="" />
        </Slide>
      </IconButton>
    </div>
  );
};
