import React from "react";
import { useWebsiteList, useWebsiteRoutes } from "hooks";
import { CustomButton } from "core";
import { AddIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { Routes } from "routes";
import { WebsiteListFilter } from "../WebsiteListFilter";
import { WebsiteListTable } from "../WebsiteListTable";

type WebsiteListProps = {
  routeKey: "websites" | "customerwebsites" | "templatewebsites";
};

export const WebsiteListPage: React.FC<WebsiteListProps> = ({ routeKey }) => {
  const { navigateToCreateWebsitePage } = useWebsiteRoutes();

  const { websiteList, isLoading, hasNextPage, fetchNextPage } =
    useWebsiteList();

  return (
    <BoxHeadlineContainer
      boxTitle="Webseiten"
      boxIcon={Routes.websitemanagement.subNavigations.websites.icon}
      boxSubTitle={websiteList.length.toString()}
      isLoading={isLoading}
      paddingHorizontal={0}
      paddingVertical={0}
      boxMenu={
        <CustomButton
          text="Neue Webseite"
          size="small"
          iconBefore={<AddIcon />}
          onClick={() => navigateToCreateWebsitePage(routeKey)}
        />
      }
    >
      <WebsiteListFilter />
      <WebsiteListTable
        routeKey={routeKey}
        listIntent="list"
        websiteList={websiteList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    </BoxHeadlineContainer>
  );
};
