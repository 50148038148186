import { client } from "queryClient";
import { EffortRule, EffortRuleSelectionSet } from "types";

export const getEffortRuleList = async (
  nextTokenParam: string | null = null,
): Promise<{ effortRuleList: EffortRule[]; nextToken: string | null }> => {
  const { data, nextToken } = await client.models.EffortRule.list({
    limit: 100,
    nextToken: nextTokenParam,
    selectionSet: EffortRuleSelectionSet,
  });

  return {
    effortRuleList: data ?? [],
    nextToken: nextToken ?? null,
  };
};

export const getAllEffortRuleList = async (
  nextTokenParam: string | null = null,
  prevEffortRuleList: EffortRule[] = [],
): Promise<{ effortRuleList: EffortRule[]; nextToken: string | null }> => {
  const { data, nextToken } = await client.models.EffortRule.list({
    limit: 200,
    nextToken: nextTokenParam,
    selectionSet: EffortRuleSelectionSet,
  });

  const effortRuleList = [...prevEffortRuleList, ...data];

  return nextToken
    ? getAllEffortRuleList(nextToken, effortRuleList)
    : { effortRuleList, nextToken: null };
};

export const getEffortRule = async (id: string): Promise<EffortRule | null> => {
  const { data } = await client.models.EffortRule.get(
    {
      id,
    },
    {
      selectionSet: EffortRuleSelectionSet,
    },
  );

  return data;
};
