import { SelectionSet } from "aws-amplify/api";
import { Schema } from "queryClient";
import { TypeKeysEnum } from "./General.types";
import utils from "utils";

export const SystemSelectionSet = [
  "id",
  "owner",
  "createdAt",
  "updatedAt",
  "name",
  "rating",
  "complexity",
  "updatesNeeded",
  "description",
  "templates.*",
  "effortRules.*",
  "websites.*",
] as const;

export type System = SelectionSet<
  Schema["System"]["type"],
  typeof SystemSelectionSet
>;

export type CreateSystemInput = Schema["System"]["createType"];

export type UpdateSystemInput = Schema["System"]["updateType"];

export const SystemTypeKeys: TypeKeysEnum<System> = {
  id: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.id,
  },
  createdAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.createdAt) +
      " Uhr",
  },
  updatedAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.updatedAt) +
      " Uhr",
  },
  owner: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: (data) => data.owner ?? "",
  },
  name: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: true,
    formatter: (data) => data.name,
  },
  rating: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.rating.toString(),
  },
  complexity: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.complexity.toString(),
  },
  updatesNeeded: {
    type: "boolean",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => (data.updatesNeeded ? "Ja" : "Nein"),
  },
  description: {
    type: "string",
    isArray: false,
    isRequired: false,
    isSortable: false,
    isSearchable: true,
    formatter: (data) => data.description ?? "",
  },
  effortRules: {
    type: "entity",
    isArray: true,
    isRequired: false,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  templates: {
    type: "entity",
    isArray: true,
    isRequired: false,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  websites: {
    type: "entity",
    isArray: true,
    isRequired: false,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
};

export const isKeyOfSystem = (key: string): key is keyof System =>
  key in SystemTypeKeys;
