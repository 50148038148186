import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useListQueryParams } from "hooks";
import {
  EffortRule,
  EffortRuleTypeKeys,
  SearchTextParamName,
  SortDirection,
  isKeyOfEffortRule,
} from "types";
import {
  FilterField,
  filterEntities,
  filterEntityByField,
  getComparisonType,
  getSearchFields,
  getSortFields,
  sortEntities,
  sortEntitiesByField,
} from "utils/tables";

const searchFields = getSearchFields<EffortRule>(EffortRuleTypeKeys);

type EffortRuleListParams = {
  systemID: string;
};

export const useEffortRuleListFilterAndSort = (filter = true) => {
  const searchParams = useListQueryParams();

  const { systemID } = useParams<EffortRuleListParams>();

  const filterFields: FilterField<EffortRule>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof EffortRule, string>> = {};

    if (systemID) {
      defaultFilters.systemID = systemID;
    }

    const filters: FilterField<EffortRule>[] = Array.from(
      searchParams.entries(),
    ).map(([field, value]) => ({
      field: field as keyof EffortRule,
      value,
      comparisonType: getComparisonType(searchParams, field),
    }));

    const defaultFilterFields: FilterField<EffortRule>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof EffortRule,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams, systemID]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof EffortRule, SortDirection>> = {
      websiteType: SortDirection.asc,
    };

    return getSortFields<EffortRule>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfEffortRule,
    );
  }, [searchParams]);

  const filterAndSortEffortRules = useCallback(
    (
      effortRuleList: EffortRule[],
      customSortFunctions: Partial<
        Record<
          keyof EffortRule,
          (a: EffortRule, b: EffortRule, direction: SortDirection) => number
        >
      > = {},
      customFilterFunctions: Partial<
        Record<keyof EffortRule, (entity: EffortRule, value: string) => boolean>
      > = {},
    ) =>
      sortEntities<EffortRule>(
        filter
          ? filterEntities<EffortRule>(
              effortRuleList,
              filterFields,
              searchFields,
              searchParams.get(SearchTextParamName.SearchEffortRule),
              filterEntityByField<EffortRule>,
              EffortRuleTypeKeys,
              customFilterFunctions,
            )
          : effortRuleList,
        sortFields,
        sortEntitiesByField<EffortRule>,
        EffortRuleTypeKeys,
        customSortFunctions,
      ).map((effortRule) => ({
        ...effortRule,
        updatedAt: new Date(
          new Date(effortRule.updatedAt).getTime() + 1000,
        ).toISOString(),
      })),
    [filter, filterFields, searchParams, sortFields],
  );

  return filterAndSortEffortRules;
};
