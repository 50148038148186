import { client } from "queryClient";
import { Template, TemplateSelectionSet } from "types";

export const getTemplateList = async (
  nextTokenParam: string | null = null,
): Promise<{ templateList: Template[]; nextToken: string | null }> => {
  const { data, nextToken } = await client.models.Template.list({
    limit: 100,
    nextToken: nextTokenParam,
    selectionSet: TemplateSelectionSet,
  });

  return {
    templateList: data ?? [],
    nextToken: nextToken ?? null,
  };
};

export const getAllTemplateList = async (
  nextTokenParam: string | null = null,
  prevTemplateList: Template[] = [],
): Promise<{ templateList: Template[]; nextToken: string | null }> => {
  const { data, nextToken } = await client.models.Template.list({
    limit: 200,
    nextToken: nextTokenParam,
    selectionSet: TemplateSelectionSet,
  });

  const templateList = [...prevTemplateList, ...data];

  return nextToken
    ? getAllTemplateList(nextToken, templateList)
    : { templateList, nextToken: null };
};

export const getTemplate = async (id: string): Promise<Template | null> => {
  const { data } = await client.models.Template.get(
    {
      id,
    },
    {
      selectionSet: TemplateSelectionSet,
    },
  );

  return data;
};

export const isTemplateUsed = async (template: Template) => {
  return template.websites.length > 0;
};
