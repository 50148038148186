import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  Booking,
  BookingTypeKeys,
  SearchTextParamName,
  SortDirection,
  isKeyOfBooking,
} from "types";
import {
  FilterField,
  filterEntities,
  filterEntityByField,
  getComparisonType,
  getSearchFields,
  getSortFields,
  sortEntities,
  sortEntitiesByField,
} from "utils/tables";

const searchFields = getSearchFields<Booking>(BookingTypeKeys);

export const useBookingListFilterAndSort = (filter = true) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<Booking>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof Booking, string>> = {};

    const filters: FilterField<Booking>[] = Array.from(
      searchParams.entries(),
    ).map(([field, value]) => ({
      field: field as keyof Booking,
      value,
      comparisonType: getComparisonType(searchParams, field),
    }));

    const defaultFilterFields: FilterField<Booking>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof Booking,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof Booking, SortDirection>> = {
      customerID: SortDirection.asc,
    };

    return getSortFields<Booking>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfBooking,
    );
  }, [searchParams]);

  const filterAndSortBookings = useCallback(
    (
      bookingList: Booking[],
      customSortFunctions: Partial<
        Record<
          keyof Booking,
          (a: Booking, b: Booking, direction: SortDirection) => number
        >
      > = {},
      customFilterFunctions: Partial<
        Record<keyof Booking, (entity: Booking, value: string) => boolean>
      > = {},
    ) =>
      sortEntities<Booking>(
        filter
          ? filterEntities<Booking>(
              bookingList,
              filterFields,
              searchFields,
              searchParams.get(SearchTextParamName.SearchBooking),
              filterEntityByField<Booking>,
              BookingTypeKeys,
              customFilterFunctions,
            )
          : bookingList,
        sortFields,
        sortEntitiesByField<Booking>,
        BookingTypeKeys,
        customSortFunctions,
      ),
    [filter, filterFields, searchParams, sortFields],
  );

  return filterAndSortBookings;
};
