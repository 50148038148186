import moment from "moment";
import "moment/dist/locale/de";

moment.locale("de");

export const getDateString = (date: string) => {
  const REFERENCE = moment(); // fixed just for testing, use moment();
  const TODAY = REFERENCE.clone().startOf("day");
  const YESTERDAY = REFERENCE.clone().subtract(1, "days").startOf("day");
  const A_WEEK_OLD = REFERENCE.clone().subtract(7, "days").startOf("day");

  if (moment(date).isSame(TODAY, "day")) {
    return "Heute, " + moment(date).format("HH:mm");
  }

  if (moment(date).isSame(YESTERDAY, "day")) {
    return "Gestern, " + moment(date).format("HH:mm");
  }

  if (moment(date).isAfter(A_WEEK_OLD)) {
    return moment(date).format("ddd") + ", " + moment(date).format("HH:mm");
  }

  if (moment(date).isSame(TODAY, "year")) {
    return moment(date).format("DD. MMM");
  }

  return moment(date).format("DD.MM.YYYY");
};

export const getFromNow = (date: string) => {
  return moment(date).fromNow();
};

export const convertDateToAWSDateFormat = (date: Date) => {
  return moment(date).locale("de").format("YYYY-MM-DD");
};

export const convertDateToAWSDateTimeFormat = (date: Date) => {
  return moment(date).toISOString();
};

export const convertDateToAWSTimestampFormat = (date: Date) => {
  return moment(date).unix();
};

export const getDateFromAWSDateFormat = (dateString: string) => {
  return moment(dateString, "YYYY-MM-DD", "de").toDate();
};

export const getDateInGermanFormat = (date: Date) => {
  return moment(date).format("DD.MM.YYYY");
};

export const getDateInGermanFromAWSDateFormat = (dateString: string) => {
  return moment(dateString, "YYYY-MM-DD", "de").format("DD.MM.YYYY");
};

export const getDateAndTimeInGermanFromAWSDateFormat = (dateString: string) => {
  return moment(dateString).format("DD.MM.YYYY - HH:mm");
};

export const getDateAndTimeInGermanFromAWSTimestampFormat = (
  timestamp: number,
) => {
  return moment.unix(timestamp).format("DD.MM.YYYY - HH:mm");
};

export const getIsoWeekFromAWSDateFormat = (dateString: string) => {
  return moment(dateString, "YYYY-MM-DD", "de").isoWeek();
};

export const getDateInGermanFromAWSDateTimeFormat = (dateString: string) => {
  return moment(dateString).format("DD.MM.YYYY");
};

export const getTimeInGermanFromAWSDateTimeFormat = (dateString: string) => {
  return moment(dateString).format("HH:mm");
};

export const getDateAndTimeInGermanFromAWSDateTimeFormat = (
  dateString: string,
) => {
  return moment(dateString).format("DD.MM.YYYY - HH:mm");
};

export const getDateInGermanExtendedFormat = (date: Date) => {
  return moment(date).format("DD. MMM YYYY");
};

export const getDateInGermanExtendedFromAWSDateFormat = (
  dateString: string,
) => {
  return moment(dateString, "YYYY-MM-DD", "de").format("DD. MMM YYYY");
};

export const getDateInGermanShortFromAWSDateFormat = (dateString: string) => {
  return moment(dateString, "YYYY-MM-DD", "de").format("DD.MM");
};

export const getYearFromAWSDateFormat = (dateString: string) => {
  return moment(dateString, "YYYY-MM-DD", "de").format("YYYY");
};

export const convertDateToAWSTimeFormat = (date: Date) => {
  return moment(date).locale("de").format("HH:mm:ss.sss");
};

export const getDateFromAWSTimeFormat = (dateString: string) => {
  return moment(dateString, "HH:mm:ss.sss", "de").toDate();
};

export const getDateFromAWSDateTimeFormat = (dateString: string) => {
  return moment(dateString).toDate();
};

export const getTimeInGermanFromAWSTimeFormat = (dateString: string) => {
  return moment(dateString, "HH:mm:ss.sss", "de").format("HH:mm");
};

export const convertDateToGermanTimeFormat = (date: Date) => {
  return moment(date).locale("de").format("HH:mm");
};

export const getDurationFromAWSDateTimeFormat = (
  startTime: string,
  endTime: string | null | undefined,
) => {
  if (!endTime) return "";

  const start = moment(startTime);
  const end = moment(endTime);
  const duration = moment.duration(end.diff(start));

  const seconds = duration.seconds();
  const minutes = duration.minutes();

  let result = "";

  if (minutes > 0) {
    result += `${minutes} Minute${minutes !== 1 ? "n" : ""}`;
  }

  if (seconds > 0) {
    if (minutes > 0) {
      result += " und ";
    }
    result += `${seconds} Sekunde${seconds !== 1 ? "n" : ""}`;
  }

  return result || "0 Sekunden";
};

export const getFirstDayOfMonthInAWSDateFormat = (
  year: number,
  month: number,
) => {
  const firstDay = new Date(year, month - 1, 1);
  firstDay.setHours(0, 0, 0, 0);
  return firstDay;
};

export const getLastDayOfMonthInAWSDateFormat = (
  year: number,
  month: number,
) => {
  const lastDay = new Date(year, month, 0);
  lastDay.setHours(24, 0, 0, 0);
  return lastDay;
};

export const isValidGermanDate = (value: Date | string | null | undefined) => {
  const date = moment(value, "DD.MM.YYYY", "de");
  console.log("isValidDate: ", date.isValid());
  return date.isValid();
};

export const getEarliestDate = (dates: Date[]) => {
  const datesSorted = dates.sort((a, b) => a.getTime() - b.getTime());

  return datesSorted[0];
};

export const getLatestDate = (dates: Date[]) => {
  const datesSorted = dates.sort((a, b) => b.getTime() - a.getTime());

  return datesSorted[0];
};

export const isSameDate = (date1: Date, date2: Date) => {
  const momentDate1 = moment(date1);
  console.log("momentDate1: ", momentDate1);
  const momentDate2 = moment(date2);
  console.log("momentDate2: ", momentDate2);
  const isSame = momentDate1.isSame(momentDate2, "day");
  console.log("isSame: ", isSame);
  return isSame;
};

export const datesOverlaps = (
  startDateA: Date,
  endDateA: Date,
  startDateB: Date,
  endDateB: Date,
) => {
  return (
    moment(startDateB).isBetween(startDateA, endDateA, undefined, "()") ||
    moment(endDateB).isBetween(startDateA, endDateA, undefined, "()") ||
    moment(startDateA).isBetween(startDateB, endDateB, undefined, "()") ||
    moment(endDateA).isBetween(startDateB, endDateB, undefined, "()")
  );
};

export const getMonthName = (month: number, short = false) => {
  switch (month) {
    case 1:
      return short ? "Jan" : "Januar";
    case 2:
      return short ? "Feb" : "Februar";
    case 3:
      return short ? "Mrz" : "März";
    case 4:
      return short ? "Apr" : "April";
    case 5:
      return short ? "Mai" : "Mai";
    case 6:
      return short ? "Jun" : "Juni";
    case 7:
      return short ? "Jul" : "Juli";
    case 8:
      return short ? "Aug" : "August";
    case 9:
      return short ? "Sep" : "September";
    case 10:
      return short ? "Okt" : "Oktober";
    case 11:
      return short ? "Nov" : "November";
    case 12:
      return short ? "Dez" : "Dezember";
    default:
      return "";
  }
};

export const getDateBeginFromAWSDateFormat = (dateString: string) => {
  return moment(dateString, "YYYY-MM-DD", "de").startOf("day").toDate();
};

export const getDateEndFromAWSDateFormat = (dateString: string) => {
  return moment(dateString, "YYYY-MM-DD", "de").endOf("day").toDate();
};

export const getDateFromGermanFormat = (dateString: string) => {
  return moment(dateString, "DD.MM.YYYY", "de").toDate();
};

export const getAgeFromAWSDateFormat = (dateString: string) => {
  return moment().diff(dateString, "years");
};

export const getAgeFromDate = (date: Date) => {
  return moment().diff(date, "years");
};
