import { useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { Website } from "types";
import { getAllWebsiteList, getWebsiteList } from "modules/websites/api";
import { useWebsiteListFilterAndSort } from "../useWebsiteListFilterAndSort";

export const useWebsiteList = (filter = true) => {
  const filterAndSortWebsites = useWebsiteListFilterAndSort(filter);

  const [loadAll, setLoadAll] = useState(false);

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<{
    websiteList: Website[];
    nextToken: string | null;
  }>({
    queryKey: loadAll ? [QueryKeys.Websites, "all"] : [QueryKeys.Websites],
    queryFn: ({ pageParam = null }) =>
      loadAll
        ? getAllWebsiteList()
        : getWebsiteList(pageParam as string | null),
    getNextPageParam: (lastPage) => lastPage.nextToken ?? undefined,
    initialPageParam: null,
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const websiteList = useMemo(() => {
    const websites = data?.pages
      ? data.pages.flatMap((page) => page.websiteList)
      : [];

    return filterAndSortWebsites(websites);
  }, [data, filterAndSortWebsites]);

  return {
    websiteList,
    fetchNextPage: (all?: boolean) => {
      if (all) setLoadAll(true);
      fetchNextPage();
    },
    hasNextPage,
    isLoading,
  };
};
