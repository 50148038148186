import React, { useContext, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { AuthContext } from "context";
import {
  useTemplateUpdate,
  useWebsiteCreate,
  useWebsiteRead,
  useWebsiteRoutes,
  useWebsiteUpdate,
} from "hooks";
import { UpdateTemplateInput } from "types";
import { CustomButton } from "core";
import { Loading } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { getWebsiteListByTemplate } from "modules/websites/api";
import { Routes } from "routes";
import { WebsiteForm, WebsiteFormHandles } from "../WebsiteForm";
import useStyles from "./styles";

type WebsiteFormPageParamsType = {
  customerID: string;
  templateID: string;
};

type WebsiteFormPageProps = {
  formIntent: "create" | "edit";
  routeKey: "websites" | "customerwebsites" | "templatewebsites";
};

export const WebsiteFormPage: React.FC<WebsiteFormPageProps> = ({
  formIntent,
  routeKey,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const { website, isLoading } = useWebsiteRead();
  const createWebsiteMutation = useWebsiteCreate();
  const updateWebsiteMutation = useWebsiteUpdate();
  const { navigateToWebsitePage } = useWebsiteRoutes();

  const updateTemplateMutation = useTemplateUpdate();

  const { customerID, templateID } = useParams<WebsiteFormPageParamsType>();

  const websiteFormRef = useRef<WebsiteFormHandles>(null);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitWebsite = async () => {
    const websiteFormInput = websiteFormRef.current?.validateWebsiteForm();
    if (!websiteFormInput) {
      console.error("WebsiteForm is invalid...");
      return;
    }

    if (!authContext.user) {
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } einer Webseite ist ein Fehler aufgetreten.`,
      );
    }

    setSubmitLoading(true);

    console.log("websiteInput: ", websiteFormInput);

    const mutateWebsiteRequest = website
      ? await updateWebsiteMutation.mutateAsync({
          ...websiteFormInput,
          id: website.id,
        })
      : await createWebsiteMutation.mutateAsync(websiteFormInput);

    if (!mutateWebsiteRequest) {
      setSubmitLoading(false);
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } einer Webseite ist ein Fehler aufgetreten.`,
      );
    }

    const websitesByTemplate = await getWebsiteListByTemplate(
      mutateWebsiteRequest.templateID,
    );

    const websitesCreatedByUs = websitesByTemplate.filter(
      (website) => website.createdByUs,
    );

    const updateTemplateInput: UpdateTemplateInput = {
      id: mutateWebsiteRequest.templateID,
      usage: websitesCreatedByUs.length,
    };

    await updateTemplateMutation.mutateAsync(updateTemplateInput);

    console.log("mutateWebsiteRequest: ", mutateWebsiteRequest);

    setSubmitLoading(false);
    navigateToWebsitePage(routeKey, mutateWebsiteRequest);
  };

  if (isLoading) {
    return (
      <BoxHeadlineContainer
        boxTitle={
          "Webseite " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
        }
      >
        <Loading
          description="Bitte warten. Webseite wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={
        "Webseite " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
      }
    >
      <WebsiteForm
        website={website}
        formIntent={formIntent}
        ref={websiteFormRef}
      />

      <Grid container direction="row" className={classes.buttons}>
        <Grid item className={classes.gridItem}>
          <CustomButton
            text={
              "Webseite " + (formIntent === "edit" ? "speichern" : "anlegen")
            }
            onClick={() => submitWebsite()}
            loading={submitLoading}
            accessKey="s"
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <CustomButton
            color="red"
            text="Abbrechen"
            accessKey="a"
            onClick={() => {
              navigate(
                routeKey === "customerwebsites" && customerID !== undefined
                  ? Routes.customers.subNavigations.customer.subNavigations.customerwebsites.path.replace(
                      ":customerID",
                      customerID,
                    )
                  : routeKey === "templatewebsites" && templateID !== undefined
                    ? Routes.templatemanagement.subNavigations.templates.subNavigations.template.subNavigations.templatewebsites.path.replace(
                        ":templateID",
                        templateID,
                      )
                    : Routes.websitemanagement.subNavigations.websites.path,
              );
            }}
            disabled={submitLoading}
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};
