import { useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { Task } from "types";
import { getAllTaskList, getTaskList } from "modules/tasks/api";
import { useTaskListFilterAndSort } from "../useTaskListFilterAndSort";

export const useTaskList = (filter = true) => {
  const filterAndSortTasks = useTaskListFilterAndSort(filter);

  const [loadAll, setLoadAll] = useState(false);

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<{
    taskList: Task[];
    nextToken: string | null;
  }>({
    queryKey: loadAll ? [QueryKeys.Tasks, "all"] : [QueryKeys.Tasks],
    queryFn: ({ pageParam }) =>
      loadAll ? getAllTaskList() : getTaskList(pageParam as string | null),
    getNextPageParam: (lastPage) => lastPage.nextToken ?? undefined,
    initialPageParam: null,
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const taskList = useMemo(() => {
    const tasks = data?.pages
      ? data.pages.flatMap((page) => page.taskList)
      : [];

    return filterAndSortTasks(tasks);
  }, [data, filterAndSortTasks]);

  return {
    taskList,
    fetchNextPage: (all?: boolean) => {
      if (all) setLoadAll(true);
      fetchNextPage();
    },
    hasNextPage,
    isLoading,
  };
};
