import React from "react";
import { ColumnDef } from "@tanstack/react-table";
import { ReportEntry, Ticket } from "types";
import { CustomReactTable } from "core";

type ReportEntryListTableProps = {
  reportEntryList: ReportEntry[];
  ticketList: Ticket[];
  isLoading: boolean;
};

export const ReportEntryListTable: React.FC<ReportEntryListTableProps> = ({
  reportEntryList,
  ticketList,
  isLoading,
}) => {
  const defaultColumns = React.useMemo<ColumnDef<ReportEntry>[]>(
    () => [
      {
        header: "Urpsrünglicher Typ",
        accessorKey: "originalType",
        accessorFn: (row) =>
          row.originalType === "task"
            ? "Pauschalaufgabe"
            : row.originalType === "ticket"
              ? "Ticket"
              : "N/A",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Ticket",
        accessorKey: "ticketID",
        accessorFn: (row) => {
          const ticket = ticketList.find(
            (ticketItem) => ticketItem.id === row.ticketID,
          );
          return ticket
            ? ticket.subject
            : row.originalType === "ticket"
              ? "Ticket " + row.ticketID + " not found!"
              : "";
        },
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Pauschalaufgabe",
        accessorKey: "taskID",
        accessorFn: (row) => row.task?.website?.url,
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Typbezeichnung",
        accessorKey: "type",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
      {
        header: "Aufwand in m",
        accessorKey: "effort",
        cell: (info) => info.getValue(),
        footer: (props) => props.column.id,
      },
    ],
    [ticketList],
  );

  return (
    <>
      <CustomReactTable<ReportEntry>
        data={reportEntryList}
        columns={defaultColumns}
        isLoadingDataList={isLoading}
        selectable={false}
      />
    </>
  );
};
