import { useNavigate } from "react-router-dom";
import { Ticket } from "types";
import { Routes } from "routes";

export const useTicketRoutes = () => {
  const navigate = useNavigate();

  const getTicketPageRoute = (ticket: Ticket) =>
    Routes.ticketing.subNavigations.tickets.subNavigations.ticket.path.replace(
      ":ticketID",
      ticket.id,
    );

  const getTicketListPageRoute = () =>
    Routes.ticketing.subNavigations.tickets.path;

  const navigateToTicketPage = (ticket: Ticket) => {
    navigate(getTicketPageRoute(ticket));
  };

  const navigateToTicketListPage = () => {
    navigate(getTicketListPageRoute());
  };

  return {
    getTicketPageRoute,
    navigateToTicketPage,
    navigateToTicketListPage,
  };
};
