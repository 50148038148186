import React from "react";
import { useBookingList, useBookingRoutes } from "hooks";
import { CustomButton } from "core";
import { AddIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { Routes } from "routes";
import { BookingListFilter } from "../BookingListFilter";
import { BookingListTable } from "../BookingListTable";

type BookingListProps = {
  routeKey: "bookings" | "customerbookings";
};

export const BookingListPage: React.FC<BookingListProps> = ({ routeKey }) => {
  const { navigateToCreateBookingPage } = useBookingRoutes();

  const { bookingList, isLoading, hasNextPage, fetchNextPage } =
    useBookingList();

  return (
    <BoxHeadlineContainer
      boxTitle="Pflegebuchungen"
      boxIcon={Routes.carebookings.subNavigations.bookings.icon}
      boxSubTitle={bookingList.length.toString()}
      isLoading={isLoading}
      paddingHorizontal={0}
      paddingVertical={0}
      boxMenu={
        <CustomButton
          text="Neue Pflegebuchung"
          size="small"
          iconBefore={<AddIcon />}
          onClick={() => navigateToCreateBookingPage(routeKey)}
        />
      }
    >
      <BookingListFilter />
      <BookingListTable
        routeKey={routeKey}
        listIntent="list"
        bookingList={bookingList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    </BoxHeadlineContainer>
  );
};
