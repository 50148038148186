import { useNavigate } from "react-router-dom";
import { TaskType } from "types";
import { Routes } from "routes";

export const useTaskTypeRoutes = () => {
  const navigate = useNavigate();

  const getCreateTaskTypePageRoute = () =>
    Routes.tasktypes.subNavigations.tasktypecreate.path;

  const getEditTaskTypePageRoute = (taskType: TaskType) =>
    Routes.tasktypes.subNavigations.tasktype.subNavigations.tasktypeedit.path.replace(
      ":taskTypeID",
      taskType.id,
    );

  const getTaskTypePageRoute = (taskType: TaskType) =>
    Routes.tasktypes.subNavigations.tasktype.path.replace(
      ":taskTypeID",
      taskType.id,
    );

  const getTaskTypeListPageRoute = () => Routes.tasktypes.path;

  const navigateToCreateTaskTypePage = () => {
    navigate(getCreateTaskTypePageRoute());
  };

  const navigateToEditTaskTypePage = (taskType: TaskType) => {
    navigate(getEditTaskTypePageRoute(taskType));
  };

  const navigateToTaskTypePage = (taskType: TaskType) => {
    navigate(getTaskTypePageRoute(taskType));
  };

  const navigateToTaskTypeListPage = () => {
    navigate(getTaskTypeListPageRoute());
  };

  return {
    getCreateTaskTypePageRoute,
    getEditTaskTypePageRoute,
    getTaskTypePageRoute,
    navigateToCreateTaskTypePage,
    navigateToEditTaskTypePage,
    navigateToTaskTypePage,
    navigateToTaskTypeListPage,
  };
};
