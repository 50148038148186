import { client } from "queryClient";
import { TaskType, TaskTypeSelectionSet } from "types";

export const getTaskTypeList = async (
  nextTokenParam: string | null = null,
): Promise<{ taskTypeList: TaskType[]; nextToken: string | null }> => {
  const { data, nextToken } = await client.models.TaskType.list({
    limit: 100,
    nextToken: nextTokenParam,
    selectionSet: TaskTypeSelectionSet,
  });

  return {
    taskTypeList: data ?? [],
    nextToken: nextToken ?? null,
  };
};

export const getAllTaskTypeList = async (
  nextTokenParam: string | null = null,
  prevTaskTypeList: TaskType[] = [],
): Promise<{ taskTypeList: TaskType[]; nextToken: string | null }> => {
  const { data, nextToken } = await client.models.TaskType.list({
    limit: 200,
    nextToken: nextTokenParam,
    selectionSet: TaskTypeSelectionSet,
  });

  const taskTypeList = [...prevTaskTypeList, ...data];

  return nextToken
    ? getAllTaskTypeList(nextToken, taskTypeList)
    : { taskTypeList, nextToken: null };
};

export const getTaskType = async (id: string): Promise<TaskType | null> => {
  const { data } = await client.models.TaskType.get(
    {
      id,
    },
    {
      selectionSet: TaskTypeSelectionSet,
    },
  );

  return data;
};

export const isTaskTypeUsed = async (taskType: TaskType) => {
  return (
    taskType.tasks.length > 0 ||
    taskType.permanentTasks.length > 0 ||
    taskType.effortRules.length > 0 ||
    taskType.effortDefinitions.length > 0
  );
};
