import React from "react";
import { useWebsiteList } from "hooks";
import { Website } from "types";
import { CustomDialog } from "core";
import { WebsiteListFilter } from "../../websitelist/WebsiteListFilter";
import { WebsiteListTable } from "../../websitelist/WebsiteListTable";

type WebsiteSelectionDialogProps = {
  dialogOpen: boolean;
  setDialogOpen: (value: React.SetStateAction<boolean>) => void;
  websiteSelectionHandler: (website: Website) => void;
  routeKey: "websites" | "customerwebsites" | "templatewebsites";
};

export const WebsiteSelectionDialog: React.FC<WebsiteSelectionDialogProps> = ({
  dialogOpen,
  setDialogOpen,
  websiteSelectionHandler,
  routeKey,
}) => {
  const { websiteList, isLoading, hasNextPage, fetchNextPage } =
    useWebsiteList();

  return (
    <CustomDialog
      dialogOpen={dialogOpen}
      positive={false}
      titleText="Webseite auswählen"
      setDialogOpen={setDialogOpen}
      showConfirm={false}
      showDecline={false}
      fullWidth={true}
      maxWidth="lg"
      contentPadding={0}
    >
      <WebsiteListFilter />
      <WebsiteListTable
        listIntent="selection"
        routeKey={routeKey}
        websiteList={websiteList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        websiteSelectionHandler={websiteSelectionHandler}
      />
    </CustomDialog>
  );
};
