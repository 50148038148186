import { useNavigate, useParams } from "react-router-dom";
import { Booking } from "types";
import { Routes } from "routes";

type BookingRoutesParamsType = {
  customerID: string;
};

export const useBookingRoutes = () => {
  const navigate = useNavigate();

  const { customerID } = useParams<BookingRoutesParamsType>();

  const getCreateBookingPageRoute = (
    routeKey: "bookings" | "customerbookings",
  ) =>
    routeKey === "customerbookings" && customerID !== undefined
      ? Routes.customers.subNavigations.customer.subNavigations.customerbookings.subNavigations.customerbookingcreate.path.replace(
          ":customerID",
          customerID,
        )
      : Routes.carebookings.subNavigations.bookings.subNavigations.bookingcreate
          .path;

  const getEditBookingPageRoute = (
    routeKey: "bookings" | "customerbookings",
    booking: Booking,
  ) =>
    routeKey === "customerbookings" && customerID !== undefined
      ? Routes.customers.subNavigations.customer.subNavigations.customerbookings.subNavigations.customerbooking.subNavigations.customerbookingedit.path
          .replace(":customerID", customerID)
          .replace(":bookingID", booking.id)
      : Routes.carebookings.subNavigations.bookings.subNavigations.booking.subNavigations.bookingedit.path.replace(
          ":bookingID",
          booking.id,
        );

  const getBookingPageRoute = (
    routeKey: "bookings" | "customerbookings",
    booking: Booking,
  ) =>
    routeKey === "customerbookings" && customerID !== undefined
      ? Routes.customers.subNavigations.customer.subNavigations.customerbookings.subNavigations.customerbooking.path
          .replace(":customerID", customerID)
          .replace(":bookingID", booking.id)
      : Routes.carebookings.subNavigations.bookings.subNavigations.booking.path.replace(
          ":bookingID",
          booking.id,
        );

  const getBookingListPageRoute = (
    routeKey: "bookings" | "customerbookings",
  ) =>
    routeKey === "customerbookings" && customerID !== undefined
      ? Routes.customers.subNavigations.customer.subNavigations.customerbookings.path.replace(
          ":customerID",
          customerID,
        )
      : Routes.carebookings.subNavigations.bookings.path;

  const navigateToCreateBookingPage = (
    routeKey: "bookings" | "customerbookings",
  ) => {
    navigate(getCreateBookingPageRoute(routeKey));
  };

  const navigateToEditBookingPage = (
    routeKey: "bookings" | "customerbookings",
    booking: Booking,
  ) => {
    navigate(getEditBookingPageRoute(routeKey, booking));
  };

  const navigateToBookingPage = (
    routeKey: "bookings" | "customerbookings",
    booking: Booking,
  ) => {
    navigate(getBookingPageRoute(routeKey, booking));
  };

  const navigateToBookingListPage = (
    routeKey: "bookings" | "customerbookings",
  ) => {
    navigate(getBookingListPageRoute(routeKey));
  };

  return {
    getCreateBookingPageRoute,
    getEditBookingPageRoute,
    getBookingPageRoute,
    navigateToCreateBookingPage,
    navigateToEditBookingPage,
    navigateToBookingPage,
    navigateToBookingListPage,
  };
};
