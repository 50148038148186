import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { usePermanentTaskRead } from "hooks";
import { PermanentTask } from "types";
import { CustomButton, LabeledTypography, Loading } from "core";
import { DeleteIcon, EditIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { Routes } from "routes";
import { PermanentTaskDeleteDialog } from "../../permanenttaskDelete/PermanentTaskDeleteDialog";
import utils from "utils";
import useStyles from "./styles";

export const PermanentTaskPage: React.FC = () => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { permanentTask, isLoading } = usePermanentTaskRead();

  const [deletePermanentTaskDialogOpen, setDeletePermanentTaskDialogOpen] =
    useState<boolean>(false);
  const [permanentTaskToDelete, setPermanentTaskToDelete] = useState<
    PermanentTask | undefined
  >();

  if (isLoading) {
    return (
      <BoxHeadlineContainer boxTitle="Daueraufgabe Details">
        <Loading
          description="Bitte warten. Daueraufgabe wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  if (permanentTask === undefined || permanentTask === null) {
    return (
      <BoxHeadlineContainer boxTitle="Daueraufgabe Details">
        Daueraufgabe nicht verfügbar!
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={
        !isLoading && permanentTask
          ? permanentTask.taskType!.name
          : "Daueraufgabe-Details"
      }
      marginTop={false}
      boxMenu={
        <>
          <CustomButton
            text="Bearbeiten"
            iconBefore={<EditIcon />}
            onClick={() =>
              navigate(
                Routes.permanenttasks.subNavigations.permanenttask.subNavigations.permanenttaskedit.path.replace(
                  ":permanentTaskID",
                  permanentTask.id,
                ),
              )
            }
            size="small"
            color="blue"
            rootClassName={classes.editButton}
            accessKey="e"
          />
          <CustomButton
            text="Löschen"
            iconBefore={<DeleteIcon />}
            onClick={() => {
              setPermanentTaskToDelete(permanentTask);
              setDeletePermanentTaskDialogOpen(true);
            }}
            size="small"
            color="red"
            accessKey="d"
          />
        </>
      }
    >
      <PermanentTaskDeleteDialog
        dialogOpen={deletePermanentTaskDialogOpen}
        setDialogOpen={setDeletePermanentTaskDialogOpen}
        permanentTask={permanentTaskToDelete}
      />
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography
            label="Aufgaben-Typ"
            content={permanentTask.taskType?.name}
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Webseite"
            content={permanentTask.website?.url}
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Aufwand in m"
            content={permanentTask.effort}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography
            label="Datum der Ausführung"
            content={utils.dates.getDateInGermanFromAWSDateFormat(
              permanentTask.referenceDate,
            )}
          />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography
            label="Periode Typ"
            content={
              permanentTask.periodType === "day"
                ? "Tag"
                : permanentTask.periodType === "week"
                  ? "Woche"
                  : "Monat"
            }
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Abrechnungsart"
            content={
              permanentTask.billType === "free"
                ? "Gratis"
                : permanentTask.billType === "maintenance"
                  ? "Pflege"
                  : "Rechnung"
            }
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};
