import { useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { Customer, SearchTextParamName } from "types";
import { getAllCustomerList, getCustomerList } from "modules/customers/api";
import { useListQueryParams } from "../../../utility/useListQueryParams";
import { useWebsiteAllList } from "../../website/useWebsiteAllList";
import { useCustomerListFilterAndSort } from "../useCustomerListFilterAndSort";

export const useCustomerList = (filter = true) => {
  const searchParams = useListQueryParams();
  const filterAndSortCustomers = useCustomerListFilterAndSort(filter);

  const { websiteList } = useWebsiteAllList(false);

  const [loadAll, setLoadAll] = useState(false);

  const searchText = useMemo(
    () => searchParams.get(SearchTextParamName.SearchCustomer) ?? null,
    [searchParams],
  );

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<{
    customerList: Customer[];
    hasNewNextData: boolean;
    nextToken: string | null;
  }>({
    queryKey: searchText
      ? [QueryKeys.Customers, "search", searchText]
      : loadAll
        ? [QueryKeys.Customers, "loadAll"]
        : [QueryKeys.Customers],
    queryFn: ({ pageParam = undefined }) =>
      loadAll
        ? getAllCustomerList()
        : getCustomerList(200, pageParam as string | null, searchText),
    getNextPageParam: (lastPage) =>
      !lastPage.hasNewNextData ? undefined : (lastPage.nextToken ?? undefined),
    initialPageParam: undefined,
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const customerList = useMemo(() => {
    const customers = data?.pages
      ? data.pages.flatMap((page) => page.customerList)
      : [];

    const customersWithWebsites = customers.map((customer) => {
      const websites = websiteList.filter(
        (website) => website.websiteOwner === customer.id,
      );

      return {
        ...customer,
        websiteAmount: websites.length,
      };
    });

    return filterAndSortCustomers(
      customersWithWebsites,
      {},
      {
        hasWebsites: (entity, value) => {
          if (value === "true") {
            return entity.websiteAmount > 0;
          }

          if (value === "false") {
            return entity.websiteAmount === 0;
          }

          return true;
        },
      },
    );
  }, [data, websiteList, filterAndSortCustomers]);

  return {
    customerList,
    fetchNextPage: (all?: boolean) => {
      if (all) setLoadAll(true);
      fetchNextPage();
    },
    hasNextPage,
    isLoading,
  };
};
