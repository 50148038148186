import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles<void, "navigationIcon">()(
  (_, _params, classes) => ({
    gridItem: {
      marginBottom: 30,
      "&:not(:last-child)": {
        marginRight: 70,
      },
    },
    listItem: {
      color: "#4f4f4f",
      border: "3px solid #e4edeb",
      borderRadius: 15,
      padding: "15px 40px !important",
      transition: "0.3s",
      lineHeight: "24px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",

      "&:hover": {
        color: "#000",
        borderColor: "#9eccc3",
        backgroundColor: "rgba(255,255,255,0.025)",
        [`& .${classes.navigationIcon}`]: {
          color: "#6eafa3",
        },
      },
    },
    listItemText: {
      fontSize: 21,
      fontWeight: 500,
      color: "#4f4f4f",
      textAlign: "left",
    },
    navigationIcon: {
      color: "#4f4f4f",
      transition: "0.3s",
      marginBottom: 15,
      "& svg": {
        width: 75,
        height: 75,
      },
    },
  })
);

export default useStyles;
