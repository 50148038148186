import React, { useContext, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { AuthContext } from "context";
import {
  useEffortRuleCreate,
  useEffortRuleRead,
  useEffortRuleRoutes,
  useEffortRuleUpdate,
} from "hooks";
import { CustomButton } from "core";
import { Loading } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { Routes } from "routes";
import { EffortRuleForm, EffortRuleFormHandles } from "../EffortRuleForm";
import useStyles from "./styles";

type EffortRuleFormPageParamsType = {
  systemID: string;
  taskTypeID: string;
};

type EffortRuleFormPageProps = {
  formIntent: "create" | "edit";
  routeKey: "effortrules" | "systemeffortrules" | "tasktypeeffortrules";
};

export const EffortRuleFormPage: React.FC<EffortRuleFormPageProps> = ({
  formIntent,
  routeKey,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const { effortRule, isLoading } = useEffortRuleRead();

  const createEffortRuleMutation = useEffortRuleCreate();
  const updateEffortRuleMutation = useEffortRuleUpdate();
  const { navigateToEffortRulePage } = useEffortRuleRoutes();

  const { systemID, taskTypeID } = useParams<EffortRuleFormPageParamsType>();

  const effortRuleFormRef = useRef<EffortRuleFormHandles>(null);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitEffortRule = async () => {
    const effortRuleFormInput =
      effortRuleFormRef.current?.validateEffortRuleForm();
    if (!effortRuleFormInput) {
      console.error("EffortRuleForm is invalid...");
      return;
    }

    if (!authContext.user) {
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } einer Aufwandsregel ist ein Fehler aufgetreten.`,
      );
    }

    setSubmitLoading(true);

    console.log("effortRuleInput: ", effortRuleFormInput);

    const mutateEffortRuleRequest = effortRule
      ? await updateEffortRuleMutation.mutateAsync({
          ...effortRuleFormInput,
          id: effortRule.id,
        })
      : await createEffortRuleMutation.mutateAsync(effortRuleFormInput);

    if (!mutateEffortRuleRequest) {
      setSubmitLoading(false);
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } einer Aufwandsregel ist ein Fehler aufgetreten.`,
      );
    }

    console.log("mutateEffortRuleRequest: ", mutateEffortRuleRequest);

    setSubmitLoading(false);
    navigateToEffortRulePage(routeKey, mutateEffortRuleRequest);
  };

  if (isLoading) {
    return (
      <BoxHeadlineContainer
        boxTitle={
          "Aufwandsregel " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
        }
      >
        <Loading
          description="Bitte warten. Aufwandsregel wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={
        "Aufwandsregel " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
      }
    >
      <EffortRuleForm
        effortRule={effortRule}
        formIntent={formIntent}
        ref={effortRuleFormRef}
      />

      <Grid container direction="row" className={classes.buttons}>
        <Grid item className={classes.gridItem}>
          <CustomButton
            text={
              "Aufwandsregel " +
              (formIntent === "edit" ? "speichern" : "anlegen")
            }
            onClick={() => submitEffortRule()}
            loading={submitLoading}
            accessKey="s"
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <CustomButton
            color="red"
            text="Abbrechen"
            accessKey="a"
            onClick={() => {
              navigate(
                routeKey === "systemeffortrules" && systemID !== undefined
                  ? Routes.systems.subNavigations.system.subNavigations.systemeffortrules.path.replace(
                      ":systemID",
                      systemID,
                    )
                  : routeKey === "tasktypeeffortrules" &&
                      taskTypeID !== undefined
                    ? Routes.tasktypes.subNavigations.tasktype.subNavigations.tasktypeeffortrules.path.replace(
                        ":taskTypeID",
                        taskTypeID,
                      )
                    : Routes.effortrules.path,
              );
            }}
            disabled={submitLoading}
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};
