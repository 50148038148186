import { client } from "queryClient";
import { System, SystemSelectionSet } from "types";

export const getSystemList = async (
  nextTokenParam: string | null = null,
): Promise<{ systemList: System[]; nextToken: string | null }> => {
  const { data, nextToken } = await client.models.System.list({
    limit: 100,
    nextToken: nextTokenParam,
    selectionSet: SystemSelectionSet,
  });

  return {
    systemList: data ?? [],
    nextToken: nextToken ?? null,
  };
};

export const getAllSystemList = async (
  nextTokenParam: string | null = null,
  prevSystemList: System[] = [],
): Promise<{ systemList: System[]; nextToken: string | null }> => {
  const { data, nextToken } = await client.models.System.list({
    limit: 200,
    nextToken: nextTokenParam,
    selectionSet: SystemSelectionSet,
  });

  const systemList = [...prevSystemList, ...data];

  return nextToken
    ? getAllSystemList(nextToken, systemList)
    : { systemList, nextToken: null };
};

export const getSystem = async (id: string): Promise<System | null> => {
  const { data } = await client.models.System.get(
    {
      id,
    },
    {
      selectionSet: SystemSelectionSet,
    },
  );

  return data;
};

export const isSystemUsed = async (system: System) => {
  return (
    system.websites.length > 0 ||
    system.templates.length > 0 ||
    system.effortRules.length > 0
  );
};
