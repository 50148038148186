import { useMutation } from "@tanstack/react-query";
import { QueryKeys, client, queryClient } from "queryClient";
import { CreateTaskInput, Task } from "types";
import { getTask } from "modules/tasks/api";

export const useTaskCreate = () => {
  const createMutation = useMutation({
    mutationFn: async (input: CreateTaskInput) => {
      const { data: newTask } = await client.models.Task.create(input);

      if (!newTask) return null;

      const task = await getTask(newTask.id);

      return task;
    },
    // When mutate is called:
    onMutate: async (newTask) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [QueryKeys.Tasks] });

      // Snapshot the previous value
      const previousTasks = queryClient.getQueryData([QueryKeys.Tasks]);

      // Optimistically update to the new value
      if (previousTasks) {
        queryClient.setQueryData([QueryKeys.Tasks], (old: Task[]) => [
          ...old,
          newTask,
        ]);
      }

      // Return a context object with the snapshotted value
      return { previousTasks };
    },
    // If the mutation fails,
    // use the context returned from onMutate to rollback
    onError: (err, newTask, context) => {
      console.error("Error saving record:", err, newTask);
      if (context?.previousTasks) {
        queryClient.setQueryData([QueryKeys.Tasks], context.previousTasks);
      }
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.Tasks] });
    },
  });

  return createMutation;
};
