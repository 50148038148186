import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  Employee,
  EmployeeTypeKeys,
  SearchTextParamName,
  SortDirection,
  isKeyOfEmployee,
} from "types";
import {
  FilterField,
  filterEntities,
  filterEntityByField,
  getComparisonType,
  getSearchFields,
  getSortFields,
  sortEntities,
  sortEntitiesByField,
} from "utils/tables";

const searchFields = getSearchFields<Employee>(EmployeeTypeKeys);

export const useEmployeeListFilterAndSort = (filter = true) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<Employee>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof Employee, string>> = {};

    const filters: FilterField<Employee>[] = Array.from(
      searchParams.entries(),
    ).map(([field, value]) => ({
      field: field as keyof Employee,
      value,
      comparisonType: getComparisonType(searchParams, field),
    }));

    const defaultFilterFields: FilterField<Employee>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof Employee,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof Employee, SortDirection>> = {
      name: SortDirection.asc,
    };

    return getSortFields<Employee>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfEmployee,
    );
  }, [searchParams]);

  const filterAndSortAgents = useCallback(
    (
      employeeList: Employee[],
      customSortFunctions: Partial<
        Record<
          keyof Employee,
          (a: Employee, b: Employee, direction: SortDirection) => number
        >
      > = {},
      customFilterFunctions: Partial<
        Record<keyof Employee, (entity: Employee, value: string) => boolean>
      > = {},
    ) =>
      sortEntities<Employee>(
        filter
          ? filterEntities<Employee>(
              employeeList,
              filterFields,
              searchFields,
              searchParams.get(SearchTextParamName.SearchEmployee),
              filterEntityByField<Employee>,
              EmployeeTypeKeys,
              customFilterFunctions,
            )
          : employeeList,
        sortFields,
        sortEntitiesByField<Employee>,
        EmployeeTypeKeys,
        customSortFunctions,
      ),
    [filter, filterFields, searchParams, sortFields],
  );

  return filterAndSortAgents;
};
