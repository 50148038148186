import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { ReportEntry } from "types";
import { getReportEntryListByBookingID } from "modules/carereport/api";
import { getTicketListByIDs } from "modules/tickets/api";
import { useListQueryParams } from "../../../utility/useListQueryParams";
import { useReportEntryListFilterAndSort } from "../useReportEntryListFilterAndSort";

type ReportEntryListByBookingParamsType = {
  bookingID: string | undefined;
};

export const useReportEntryListByBooking = (filter = true) => {
  const searchParams = useListQueryParams();
  const filterAndSortReportEntries = useReportEntryListFilterAndSort(filter);

  const { bookingID } = useParams<ReportEntryListByBookingParamsType>();

  const { data: reportEntryListData, isLoading } = useQuery({
    queryKey: [QueryKeys.ReportEntries],
    queryFn: async () => {
      const { reportEntryList } =
        await getReportEntryListByBookingID(bookingID);

      return reportEntryList;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const ticketIDs = useMemo(
    () =>
      (reportEntryListData ?? [])
        .filter(
          (reportEntry) =>
            reportEntry.ticketID !== null && reportEntry.ticketID !== undefined,
        )
        .map((reportEntry) => reportEntry.ticketID)
        .join(","),
    [reportEntryListData],
  );

  const { data: ticketListData, isLoading: isLoadingTickets } = useQuery({
    queryKey: [QueryKeys.Tickets, { ids: ticketIDs }],
    queryFn: async () => {
      const ticketList = await getTicketListByIDs(ticketIDs);

      return ticketList;
    },
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const reportEntryList = useMemo(() => {
    const customSortFunctions = {
      ticketID: (a: ReportEntry, b: ReportEntry) => {
        if (!a.ticketID && b.ticketID) {
          return 1;
        }
        if (a.ticketID && !b.ticketID) {
          return -1;
        }
        if (!a.ticketID && !b.ticketID) {
          return 0;
        }
        const ticketA = ticketListData?.find(
          (ticket) => ticket.id === a.ticketID,
        );
        const ticketB = ticketListData?.find(
          (ticket) => ticket.id === b.ticketID,
        );
        if (!ticketA && ticketB) {
          return 1;
        }
        if (ticketA && !ticketB) {
          return -1;
        }
        if (!ticketA && !ticketB) {
          return 0;
        }
        return searchParams.get("sortOrder") === "asc"
          ? ticketA!.subject.localeCompare(ticketB!.subject)
          : ticketB!.subject.localeCompare(ticketA!.subject);
      },
    };

    return filterAndSortReportEntries(
      reportEntryListData ?? [],
      customSortFunctions,
    );
  }, [
    reportEntryListData,
    filterAndSortReportEntries,
    ticketListData,
    searchParams,
  ]);

  return {
    reportEntryList,
    ticketList: ticketListData ?? [],
    isLoading,
    isLoadingTickets,
  };
};
