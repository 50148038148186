import { SelectionSet } from "aws-amplify/api";
import { Schema } from "queryClient";
import { TypeKeysEnum } from "./General.types";
import utils from "utils";

export const EffortDefinitionSelectionSet = [
  "id",
  "owner",
  "createdAt",
  "updatedAt",
  "taskType.*",
  "taskTypeID",
  "website.*",
  "websiteID",
  "effort",
] as const;

export type EffortDefinition = SelectionSet<
  Schema["EffortDefinition"]["type"],
  typeof EffortDefinitionSelectionSet
>;

export type CreateEffortDefinitionInput =
  Schema["EffortDefinition"]["createType"];

export type UpdateEffortDefinitionInput =
  Schema["EffortDefinition"]["updateType"];

export const EffortDefinitionTypeKeys: TypeKeysEnum<EffortDefinition> = {
  id: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.id,
  },
  createdAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.createdAt) +
      " Uhr",
  },
  updatedAt: {
    type: "datetime",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) =>
      utils.dates.getDateAndTimeInGermanFromAWSDateTimeFormat(data.updatedAt) +
      " Uhr",
  },
  owner: {
    type: "string",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: (data) => data.owner ?? "",
  },
  taskType: {
    type: "entity",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  taskTypeID: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.taskTypeID,
  },
  website: {
    type: "entity",
    isArray: false,
    isRequired: true,
    isSortable: false,
    isSearchable: false,
    formatter: () => "",
  },
  websiteID: {
    type: "id",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.websiteID,
  },
  effort: {
    type: "integer",
    isArray: false,
    isRequired: true,
    isSortable: true,
    isSearchable: false,
    formatter: (data) => data.effort.toString(),
  },
};

export const isKeyOfEffortDefinition = (
  key: string,
): key is keyof EffortDefinition => key in EffortDefinitionTypeKeys;
