import { useMemo, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { QueryKeys } from "queryClient";
import { TaskType } from "types";
import { getAllTaskTypeList, getTaskTypeList } from "modules/tasktypes/api";
import { useTaskTypeListFilterAndSort } from "../useTaskTypeListFilterAndSort";

export const useTaskTypeList = (filter = true) => {
  const filterAndSortTaskTypes = useTaskTypeListFilterAndSort(filter);

  const [loadAll, setLoadAll] = useState(false);

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery<{
    taskTypeList: TaskType[];
    nextToken: string | null;
  }>({
    queryKey: loadAll ? [QueryKeys.TaskTypes, "all"] : [QueryKeys.TaskTypes],
    queryFn: ({ pageParam }) =>
      loadAll
        ? getAllTaskTypeList()
        : getTaskTypeList(pageParam as string | null),
    getNextPageParam: (lastPage) => lastPage.nextToken ?? undefined,
    initialPageParam: null,
    staleTime: 1000 * 60 * 15, // 15 minutes
    gcTime: 1000 * 60 * 60, // 1 hour
  });

  const taskTypeList = useMemo(() => {
    const taskTypes = data?.pages
      ? data.pages.flatMap((page) => page.taskTypeList)
      : [];

    return filterAndSortTaskTypes(taskTypes);
  }, [data, filterAndSortTaskTypes]);

  return {
    taskTypeList,
    fetchNextPage: (all?: boolean) => {
      if (all) setLoadAll(true);
      fetchNextPage();
    },
    hasNextPage,
    isLoading,
  };
};
