import { useNavigate } from "react-router-dom";
import { Customer } from "types";
import { Routes } from "routes";

export const useCustomerRoutes = () => {
  const navigate = useNavigate();

  const getCustomerPageRoute = (customer: Customer) =>
    Routes.customers.subNavigations.customer.path.replace(
      ":customerID",
      customer.id,
    );

  const getCustomerListPageRoute = () => Routes.customers.path;

  const navigateToCustomerPage = (customer: Customer) => {
    navigate(getCustomerPageRoute(customer));
  };

  const navigateToCustomerListPage = () => {
    navigate(getCustomerListPageRoute());
  };

  return {
    getCustomerPageRoute,
    navigateToCustomerPage,
    navigateToCustomerListPage,
  };
};
