import { useNavigate } from "react-router-dom";
import { Template } from "types";
import { Routes } from "routes";

export const useTemplateRoutes = () => {
  const navigate = useNavigate();

  const getCreateTemplatePageRoute = () =>
    Routes.templatemanagement.subNavigations.templates.subNavigations
      .templatecreate.path;

  const getEditTemplatePageRoute = (template: Template) =>
    Routes.templatemanagement.subNavigations.templates.subNavigations.template.subNavigations.templateedit.path.replace(
      ":templateID",
      template.id,
    );

  const getTemplatePageRoute = (template: Template) =>
    Routes.templatemanagement.subNavigations.templates.subNavigations.template.path.replace(
      ":templateID",
      template.id,
    );

  const getTemplateListPageRoute = () =>
    Routes.templatemanagement.subNavigations.templates.path;

  const navigateToCreateTemplatePage = () => {
    navigate(getCreateTemplatePageRoute());
  };

  const navigateToEditTemplatePage = (template: Template) => {
    navigate(getEditTemplatePageRoute(template));
  };

  const navigateToTemplatePage = (template: Template) => {
    navigate(getTemplatePageRoute(template));
  };

  const navigateToTemplateListPage = () => {
    navigate(getTemplateListPageRoute());
  };

  const openTemplateInBrowserHandler = (template: Template) => {
    window.open(
      template.providerURL.match(/^https?:\/\//i)
        ? template.providerURL
        : "http://" + template.providerURL,
      "_blank",
    );
  };

  return {
    getCreateTemplatePageRoute,
    getEditTemplatePageRoute,
    getTemplatePageRoute,
    navigateToCreateTemplatePage,
    navigateToEditTemplatePage,
    navigateToTemplatePage,
    navigateToTemplateListPage,
    openTemplateInBrowserHandler,
  };
};
