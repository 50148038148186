import React from "react";
import { useHosterList, useHosterRoutes } from "hooks";
import { CustomButton } from "core";
import { AddIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { Routes } from "routes";
import { HosterListFilter } from "../HosterListFilter";
import { HosterListTable } from "../HosterListTable";

export const HosterListPage: React.FC = () => {
  const { navigateToCreateHosterPage } = useHosterRoutes();

  const { hosterList, isLoading, hasNextPage, fetchNextPage } = useHosterList();

  return (
    <BoxHeadlineContainer
      boxTitle="Hoster"
      boxIcon={Routes.hosters.icon}
      boxSubTitle={hosterList.length.toString()}
      isLoading={isLoading}
      paddingHorizontal={0}
      paddingVertical={0}
      boxMenu={
        <CustomButton
          text="Neuer Hoster"
          size="small"
          iconBefore={<AddIcon />}
          onClick={() => navigateToCreateHosterPage()}
        />
      }
    >
      <HosterListFilter />
      <HosterListTable
        listIntent="list"
        hosterList={hosterList}
        isLoading={isLoading}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
      />
    </BoxHeadlineContainer>
  );
};
