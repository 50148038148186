import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  SearchTextParamName,
  SortDirection,
  Ticket,
  TicketTypeKeys,
  isKeyOfTicket,
} from "types";
import {
  FilterField,
  filterEntities,
  filterEntityByField,
  getComparisonType,
  getSearchFields,
  getSortFields,
  sortEntities,
  sortEntitiesByField,
} from "utils/tables";

const searchFields = getSearchFields<Ticket>(TicketTypeKeys);

export const useTicketListFilterAndSort = (filter = true) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<Ticket>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof Ticket, string>> = {};

    const filters: FilterField<Ticket>[] = Array.from(
      searchParams.entries(),
    ).map(([field, value]) => ({
      field: field as keyof Ticket,
      value,
      comparisonType: getComparisonType(searchParams, field),
    }));

    const defaultFilterFields: FilterField<Ticket>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof Ticket,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof Ticket, SortDirection>> = {
      accountName: SortDirection.asc,
    };

    return getSortFields<Ticket>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfTicket,
    );
  }, [searchParams]);

  const filterAndSortTickets = useCallback(
    (
      ticketList: Ticket[],
      customSortFunctions: Partial<
        Record<
          keyof Ticket,
          (a: Ticket, b: Ticket, direction: SortDirection) => number
        >
      > = {},
      customFilterFunctions: Partial<
        Record<keyof Ticket, (entity: Ticket, value: string) => boolean>
      > = {},
    ) =>
      sortEntities<Ticket>(
        filter
          ? filterEntities<Ticket>(
              ticketList,
              filterFields,
              searchFields,
              searchParams.get(SearchTextParamName.SearchTicket),
              filterEntityByField<Ticket>,
              TicketTypeKeys,
              customFilterFunctions,
            )
          : ticketList,
        sortFields,
        sortEntitiesByField<Ticket>,
        TicketTypeKeys,
        customSortFunctions,
      ),
    [filter, filterFields, searchParams, sortFields],
  );

  return filterAndSortTickets;
};
