import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { SelectOption } from "types";
import { CustomNumberField, CustomSelect } from "core";

type NumberFilterProps = {
  param: string;
  paramName: string;
  decimalScale?: number;
  unitAdornment?: React.ReactNode;
};

export const NumberFilter: React.FC<NumberFilterProps> = ({
  param,
  paramName,
  decimalScale,
  unitAdornment,
}) => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const comparisonParamName = useMemo(() => "comparison_" + param, [param]);

  const [number, setNumber] = useState<number | "">("");

  const [comparisonNumber, setComparisonNumber] = useState<SelectOption | null>(
    {
      label: "Gleich",
      value: "eq",
    },
  );

  const comparisonOptionList = useMemo(
    () => [
      {
        label: "Gleich",
        value: "eq",
      },
      {
        label: "Größer",
        value: "gt",
      },
      {
        label: "Kleiner",
        value: "lt",
      },
    ],
    [],
  );

  useEffect(() => {
    const numberParam = searchParams.get(param);
    const numberParamInput =
      numberParam === null ? "" : parseFloat(numberParam);
    setNumber(numberParamInput);

    const comparisonParam = searchParams.get(comparisonParamName);

    const selectedComparison = comparisonOptionList.find(
      (customerOption) => customerOption.value === comparisonParam,
    );

    const selectedComparisonOption =
      comparisonParam === null ||
      comparisonParam === "eq" ||
      !selectedComparison
        ? {
            label: "Gleich",
            value: "eq",
          }
        : selectedComparison;

    setComparisonNumber(selectedComparisonOption);
  }, [comparisonOptionList, comparisonParamName, param, searchParams]);

  return (
    <Grid container direction="row" spacing={2} wrap="nowrap">
      <Grid item>
        <CustomSelect<SelectOption>
          label={paramName + " ist:"}
          options={comparisonOptionList}
          onChange={(comparisonBookedMinutesOption) => {
            setComparisonNumber(comparisonBookedMinutesOption);
            if (comparisonBookedMinutesOption!.value === "eq") {
              searchParams.delete(comparisonParamName);
            } else {
              searchParams.set(
                comparisonParamName,
                comparisonBookedMinutesOption!.value,
              );
            }
            sessionStorage.setItem(pathname, searchParams.toString());
            setSearchParams(searchParams, {
              replace: true,
            });
          }}
          value={comparisonNumber}
        />
      </Grid>
      <Grid item>
        <CustomNumberField
          label="als"
          number={number}
          onChange={(value) => {
            setNumber(value);
            if (value === "") {
              searchParams.delete(param);
            } else {
              searchParams.set(param, value.toString());
            }
            sessionStorage.setItem(pathname, searchParams.toString());
            setSearchParams(searchParams, {
              replace: true,
            });
          }}
          decimalScale={decimalScale}
          unitAdornment={unitAdornment}
        />
      </Grid>
    </Grid>
  );
};
