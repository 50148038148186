import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { AuthContext } from "context";
import {
  useSystemCreate,
  useSystemRead,
  useSystemRoutes,
  useSystemUpdate,
} from "hooks";
import { CustomButton } from "core";
import { Loading } from "core";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { Routes } from "routes";
import { SystemForm, SystemFormHandles } from "../SystemForm";
import useStyles from "./styles";

type SystemFormPageProps = {
  formIntent: "create" | "edit";
};

export const SystemFormPage: React.FC<SystemFormPageProps> = ({
  formIntent,
}) => {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();

  const { system, isLoading } = useSystemRead();
  const createSystemMutation = useSystemCreate();
  const updateSystemMutation = useSystemUpdate();
  const { navigateToSystemPage } = useSystemRoutes();

  const systemFormRef = useRef<SystemFormHandles>(null);

  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const submitSystem = async () => {
    const systemFormInput = systemFormRef.current?.validateSystemForm();
    if (!systemFormInput) {
      console.error("SystemForm is invalid...");
      return;
    }

    if (!authContext.user) {
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } eines Systems ist ein Fehler aufgetreten.`,
      );
    }

    setSubmitLoading(true);

    console.log("systemInput: ", systemFormInput);

    const mutateSystemRequest = system
      ? await updateSystemMutation.mutateAsync({
          ...systemFormInput,
          id: system.id,
        })
      : await createSystemMutation.mutateAsync(systemFormInput);

    if (!mutateSystemRequest) {
      setSubmitLoading(false);
      return enqueueSnackbar(
        `Beim ${
          formIntent === "edit" ? "Bearbeiten" : "Anlegen"
        } eines Systems ist ein Fehler aufgetreten.`,
      );
    }

    console.log("mutateSystemRequest: ", mutateSystemRequest);

    setSubmitLoading(false);
    navigateToSystemPage(mutateSystemRequest);
  };

  if (isLoading) {
    return (
      <BoxHeadlineContainer
        boxTitle={
          "System " + (formIntent === "edit" ? "bearbeiten" : "anlegen")
        }
      >
        <Loading
          description="Bitte warten. System wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={"System " + (formIntent === "edit" ? "bearbeiten" : "anlegen")}
    >
      <SystemForm system={system} formIntent={formIntent} ref={systemFormRef} />

      <Grid container direction="row" className={classes.buttons}>
        <Grid item className={classes.gridItem}>
          <CustomButton
            text={"System " + (formIntent === "edit" ? "speichern" : "anlegen")}
            onClick={() => submitSystem()}
            loading={submitLoading}
            accessKey="s"
          />
        </Grid>
        <Grid item className={classes.gridItem}>
          <CustomButton
            color="red"
            text="Abbrechen"
            accessKey="a"
            onClick={() => {
              navigate(Routes.systems.path);
            }}
            disabled={submitLoading}
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};
