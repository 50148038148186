import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { useWebsiteRead } from "hooks";
import { Website } from "types";
import { CustomButton, CustomLink, LabeledTypography, Loading } from "core";
import { DeleteIcon, EditIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { Routes } from "routes";
import { getBacklinkStatusName } from "../../../shared/options/BacklinkStatusOption";
import { WebsiteDeleteDialog } from "../../websiteDelete/WebsiteDeleteDialog";
import useStyles from "./styles";

type WebsitePageProps = {
  routeKey: "websites" | "customerwebsites" | "templatewebsites";
};
type WebsitePageParamsType = {
  customerID: string;
  templateID: string;
};

export const WebsitePage: React.FC<WebsitePageProps> = ({ routeKey }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { customerID, templateID } = useParams<WebsitePageParamsType>();

  const { website, websiteOwner, websitePayer, isLoading } = useWebsiteRead();

  const [deleteWebsiteDialogOpen, setDeleteWebsiteDialogOpen] =
    useState<boolean>(false);
  const [websiteToDelete, setWebsiteToDelete] = useState<Website | undefined>();

  if (isLoading) {
    return (
      <BoxHeadlineContainer boxTitle="Website Details">
        <Loading
          description="Bitte warten. Website wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  if (website === undefined || website === null) {
    return (
      <BoxHeadlineContainer boxTitle="Website Details">
        Website nicht verfügbar!
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={!isLoading && website ? website.url : "Website-Details"}
      marginTop={false}
      boxMenu={
        <>
          <CustomButton
            text="Bearbeiten"
            iconBefore={<EditIcon />}
            onClick={() =>
              navigate(
                routeKey === "customerwebsites" && customerID !== undefined
                  ? Routes.customers.subNavigations.customer.subNavigations.customerwebsites.subNavigations.customerwebsite.subNavigations.customerwebsiteedit.path
                      .replace(":customerID", customerID)
                      .replace(":websiteID", website.id)
                  : routeKey === "templatewebsites" && templateID !== undefined
                    ? Routes.templatemanagement.subNavigations.templates.subNavigations.template.subNavigations.templatewebsites.subNavigations.templatewebsite.subNavigations.templatewebsiteedit.path
                        .replace(":templateID", templateID)
                        .replace(":websiteID", website.id)
                    : Routes.websitemanagement.subNavigations.websites.subNavigations.website.subNavigations.websiteedit.path.replace(
                        ":websiteID",
                        website.id,
                      ),
              )
            }
            size="small"
            color="blue"
            rootClassName={classes.editButton}
            accessKey="e"
          />
          <CustomButton
            text="Löschen"
            iconBefore={<DeleteIcon />}
            onClick={() => {
              setWebsiteToDelete(website);
              setDeleteWebsiteDialogOpen(true);
            }}
            size="small"
            color="red"
            accessKey="d"
          />
        </>
      }
    >
      <WebsiteDeleteDialog
        dialogOpen={deleteWebsiteDialogOpen}
        setDialogOpen={setDeleteWebsiteDialogOpen}
        website={websiteToDelete}
        routeKey={routeKey}
      />
      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography label="URL" content={website.url} />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Besitzer"
            content={
              websiteOwner ? (
                <CustomLink
                  linkText={websiteOwner.Account_Name}
                  link={Routes.customers.subNavigations.customer.path.replace(
                    ":customerID",
                    websiteOwner.id,
                  )}
                  target="_blank"
                />
              ) : (
                website.websiteOwner
              )
            }
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Zahler"
            content={
              websitePayer ? (
                <CustomLink
                  linkText={websitePayer.Account_Name}
                  link={Routes.customers.subNavigations.customer.path.replace(
                    ":customerID",
                    websitePayer.id,
                  )}
                  target="_blank"
                />
              ) : (
                website.websitePayer
              )
            }
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography
            label="Website-Typ"
            content={website.websiteType}
          />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography label="System" content={website.system?.name} />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Laufzeitumgebung"
            content={website.runtimeEnvironment}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography
            label="Template"
            content={website.template?.name}
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography label="Hoster" content={website.hoster?.name} />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography
            label="GW Backlink Erstellung"
            content={getBacklinkStatusName(website.backlinkErstellung)}
          />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography
            label="GW Backlink Pflege"
            content={getBacklinkStatusName(website.backlinkPflege)}
          />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography
            label="Formilo Backlink"
            content={getBacklinkStatusName(website.formiloBacklink)}
          />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography label="Bewertung" content={website.rating} />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography
            label="Von uns erstellt"
            content={website.createdByUs ? "JA" : "NEIN"}
          />
        </Grid>
        <Grid item md={4}>
          <LabeledTypography
            label="Aktuell Zuständig"
            content={website.currentlyResponsible ? "JA" : "NEIN"}
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};
