import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { useEffortRuleRead } from "hooks";
import { EffortRule } from "types";
import { CustomButton, LabeledTypography, Loading } from "core";
import { DeleteIcon, EditIcon } from "components";
import { BoxHeadlineContainer } from "layout/Container/BoxHeadlineContainer";
import { getWebsiteTypeName } from "modules/shared/options/WebsiteTypeOption";
import { Routes } from "routes";
import { EffortRuleDeleteDialog } from "../../effortruleDelete/EffortRuleDeleteDialog";
import useStyles from "./styles";

type EffortRulePageProps = {
  routeKey: "effortrules" | "systemeffortrules" | "tasktypeeffortrules";
};
type EffortRulePageParamsType = {
  systemID: string;
  taskTypeID: string;
};

export const EffortRulePage: React.FC<EffortRulePageProps> = ({ routeKey }) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { systemID, taskTypeID } = useParams<EffortRulePageParamsType>();

  const { effortRule, isLoading } = useEffortRuleRead();

  const [deleteEffortRuleDialogOpen, setDeleteEffortRuleDialogOpen] =
    useState<boolean>(false);
  const [effortRuleToDelete, setEffortRuleToDelete] = useState<
    EffortRule | undefined
  >();

  if (isLoading) {
    return (
      <BoxHeadlineContainer boxTitle="Aufwandsregel Details">
        <Loading
          description="Bitte warten. Aufwandsregel wird geladen... "
          size="25px"
        />
      </BoxHeadlineContainer>
    );
  }

  if (effortRule === undefined || effortRule === null) {
    return (
      <BoxHeadlineContainer boxTitle="Aufwandsregel Details">
        Aufwandsregel nicht verfügbar!
      </BoxHeadlineContainer>
    );
  }

  return (
    <BoxHeadlineContainer
      boxTitle={
        !isLoading && effortRule
          ? effortRule.taskType.name
          : "Aufwandsregel-Details"
      }
      marginTop={false}
      boxMenu={
        <>
          <CustomButton
            text="Bearbeiten"
            iconBefore={<EditIcon />}
            onClick={() =>
              navigate(
                routeKey === "systemeffortrules" && systemID !== undefined
                  ? Routes.systems.subNavigations.system.subNavigations.systemeffortrules.subNavigations.systemeffortrule.subNavigations.systemeffortruleedit.path
                      .replace(":systemID", systemID)
                      .replace(":effortRuleID", effortRule.id)
                  : routeKey === "tasktypeeffortrules" &&
                      taskTypeID !== undefined
                    ? Routes.tasktypes.subNavigations.tasktype.subNavigations.tasktypeeffortrules.subNavigations.tasktypeeffortrule.subNavigations.tasktypeeffortruleedit.path
                        .replace(":taskTypeID", taskTypeID)
                        .replace(":effortRuleID", effortRule.id)
                    : Routes.effortrules.subNavigations.effortrule.subNavigations.effortruleedit.path.replace(
                        ":effortRuleID",
                        effortRule.id,
                      ),
              )
            }
            size="small"
            color="blue"
            accessKey="e"
            rootClassName={classes.editButton}
          />
          <CustomButton
            text="Löschen"
            iconBefore={<DeleteIcon />}
            onClick={() => {
              setEffortRuleToDelete(effortRule);
              setDeleteEffortRuleDialogOpen(true);
            }}
            size="small"
            color="red"
            accessKey="d"
          />
        </>
      }
    >
      <EffortRuleDeleteDialog
        dialogOpen={deleteEffortRuleDialogOpen}
        setDialogOpen={setDeleteEffortRuleDialogOpen}
        effortRule={effortRuleToDelete}
        routeKey={routeKey}
      />

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography
            label="Website-Typ"
            content={getWebsiteTypeName(effortRule.websiteType)}
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography label="System" content={effortRule.system.name} />
        </Grid>
      </Grid>

      <Grid container direction="row" className={classes.gridRow}>
        <Grid item md={4}>
          <LabeledTypography
            label="Aufgaben-Typ"
            content={effortRule.taskType.name}
          />
        </Grid>

        <Grid item md={4}>
          <LabeledTypography
            label="Aufwand in Minuten"
            content={effortRule.effort}
          />
        </Grid>
      </Grid>
    </BoxHeadlineContainer>
  );
};
