import { useCallback, useMemo } from "react";
import { useListQueryParams } from "hooks";
import {
  PermanentTask,
  PermanentTaskTypeKeys,
  SearchTextParamName,
  SortDirection,
  isKeyOfPermanentTask,
} from "types";
import {
  FilterField,
  filterEntities,
  filterEntityByField,
  getComparisonType,
  getSearchFields,
  getSortFields,
  sortEntities,
  sortEntitiesByField,
} from "utils/tables";

const searchFields = getSearchFields<PermanentTask>(PermanentTaskTypeKeys);

export const usePermanentTaskListFilterAndSort = (filter = true) => {
  const searchParams = useListQueryParams();

  const filterFields: FilterField<PermanentTask>[] = useMemo(() => {
    const defaultFilters: Partial<Record<keyof PermanentTask, string>> = {};

    const filters: FilterField<PermanentTask>[] = Array.from(
      searchParams.entries(),
    ).map(([field, value]) => ({
      field: field as keyof PermanentTask,
      value,
      comparisonType: getComparisonType(searchParams, field),
    }));

    const defaultFilterFields: FilterField<PermanentTask>[] = Object.entries(
      defaultFilters,
    )
      .filter(
        ([key, value]) =>
          value !== undefined &&
          !filters.some((filterField) => filterField.field === key),
      )
      .map(([field, value]) => ({
        field: field as keyof PermanentTask,
        value,
        comparisonType: getComparisonType(searchParams, field),
      }));

    const allFilters = [...filters, ...defaultFilterFields];

    return allFilters;
  }, [searchParams]);

  const sortFields = useMemo(() => {
    const defaultSorts: Partial<Record<keyof PermanentTask, SortDirection>> = {
      referenceDate: SortDirection.asc,
    };

    return getSortFields<PermanentTask>(
      searchParams.get("sort"),
      defaultSorts,
      isKeyOfPermanentTask,
    );
  }, [searchParams]);

  const filterAndSortPermanentTasks = useCallback(
    (
      permanentTaskList: PermanentTask[],
      customSortFunctions: Partial<
        Record<
          keyof PermanentTask,
          (
            a: PermanentTask,
            b: PermanentTask,
            direction: SortDirection,
          ) => number
        >
      > = {},
      customFilterFunctions: Partial<
        Record<
          keyof PermanentTask,
          (entity: PermanentTask, value: string) => boolean
        >
      > = {},
    ) =>
      sortEntities<PermanentTask>(
        filter
          ? filterEntities<PermanentTask>(
              permanentTaskList,
              filterFields,
              searchFields,
              searchParams.get(SearchTextParamName.SearchPermanentTask),
              filterEntityByField<PermanentTask>,
              PermanentTaskTypeKeys,
              customFilterFunctions,
            )
          : permanentTaskList,
        sortFields,
        sortEntitiesByField<PermanentTask>,
        PermanentTaskTypeKeys,
        customSortFunctions,
      ).map((permanentTask) => ({
        ...permanentTask,
        updatedAt: new Date(
          new Date(permanentTask.updatedAt).getTime() + 1000,
        ).toISOString(),
      })),
    [filter, filterFields, searchParams, sortFields],
  );

  return filterAndSortPermanentTasks;
};
